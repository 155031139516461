import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Character } from "types/Character/type";
import React from "react";
import { API_URL } from "service/root/rootApi";
import { DoNotDisturb } from "@mui/icons-material";

type CharacterCardProps = {
  character: Character;
  constellation?: number;
  isEditingBox: boolean;
  isUnavailable: boolean;
  onChangeConstellation: (name: string, constellation: number) => void;
  onChangeAvailability: (name: string, constellation: number) => void;
};

const CharacterCard = (props: CharacterCardProps) => {
  const {
    character,
    constellation,
    isEditingBox,
    isUnavailable,
    onChangeConstellation,
    onChangeAvailability,
  } = props;

  return (
    <Card
      sx={{
        height: 210,
        width: 180,
        px: "0.25rem",
        position: "relative",
        bgcolor: ({ palette }) =>
          isUnavailable
            ? palette.error.main
            : constellation !== undefined
            ? palette.background.pick
            : "transparent",
      }}
    >
      <Box
        sx={{
          height: "100%",
          borderRadius: "0.75rem",
          bgcolor: ({ palette }) => palette.background.dark,
        }}
      >
        {isUnavailable && (
          <DoNotDisturb
            color="error"
            sx={{ position: "absolute", height: 180, width: 180 }}
          />
        )}
        <Button
          disabled={!isEditingBox && constellation === undefined}
          sx={{
            width: "100%",
            height: "180px",
            padding: 0,
          }}
          onClick={() =>
            isEditingBox
              ? onChangeConstellation(
                  character.name,
                  constellation !== undefined ? -1 : 0
                )
              : onChangeAvailability(character.name, constellation ?? 0)
          }
        >
          <CardMedia
            component="img"
            sx={{
              filter: constellation === undefined ? "grayscale(100%)" : "none",
            }}
            src={`${API_URL}assets/characters/${character.name}/profile.png`}
            title={character.name}
          />
        </Button>
        <CardActions sx={{ justifyContent: "center", padding: 0 }}>
          <ToggleButtonGroup
            sx={{
              width: "100%",
              justifyContent: "space-between",
              ".MuiToggleButton-root, .MuiToggleButtonGroup-grouped.Mui-selected":
                {
                  px: (theme) => theme.spacing(0.75),
                  py: 0,
                },
              ".MuiToggleButtonGroup-grouped:not(:last-of-type),.MuiToggleButtonGroup-grouped:not(:first-of-type), .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected":
                {
                  borderRadius: "100%",
                  border: "1px solid white",
                  width: "100%",
                },
              ".MuiToggleButtonGroup-grouped.Mui-selected,.MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected":
                {
                  border: ({ palette }) =>
                    `1px solid ${
                      isUnavailable
                        ? palette.error.main
                        : palette.background.pick
                    }`,
                },
            }}
          >
            {[...Array(7).keys()].map((value) => (
              <ToggleButton
                disabled={!isEditingBox}
                onClick={(_, value) =>
                  onChangeConstellation(
                    character.name,
                    value === constellation ? -1 : value
                  )
                }
                key={value}
                value={value}
                selected={value <= (constellation ?? -1)}
                size="small"
              >
                {value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </CardActions>
      </Box>
    </Card>
  );
};

export default CharacterCard;
