import API from "service/root/rootApi";
import { PatchNote, Sheet, SheetFilter } from "./type";

export const getSheets = async (payload?: SheetFilter) => {
  const response = await API.get<Sheet[]>("/sheets", { params: payload });
  return response.data;
};

export const getSheet = async (id?: string) => {
  const response = await API.get<Sheet>(`/sheet/${id}`);
  return response.data;
};

export const createSheet = async (name: string) => {
  await API.post(`/sheet`, name);
};

export const updateSheet = async (id?: string, sheet?: Sheet) => {
  await API.post(`/sheet/${id}`, sheet);
};

export const deleteSheet = async (id?: string) => {
  await API.delete(`/sheet/${id}`);
};

export const getPatchNotes = async (id?: number) => {
  const response = await API.get<PatchNote[]>(`/sheet/${id}/patchnotes`);
  return response.data;
};
