import { Player } from "service/player/type";
import { Socket } from "socket.io-client";
import { DraftStep } from "types/Draft/type";
import { CharacterBox } from "types/Player/type";
import { RoomPlayer } from "types/Room/type";

export const whoAMI = (
  socket: Socket,
  player1?: RoomPlayer,
  player2?: RoomPlayer
): "Player 1" | "Player 2" | "Spectator" => {
  if (socket.id === player1?.roomId) return "Player 1";
  else if (socket.id === player2?.roomId) return "Player 2";
  else return "Spectator";
};

export const isUserAdmin = (player?: Player) =>
  player?.roles?.includes("ADMIN") ?? false;
export const isUserOrganisator = (player?: Player) =>
  player?.roles?.includes("ORGA") ?? false;

export const isMyTurn = (
  socket: Socket,
  step?: DraftStep,
  player1?: RoomPlayer,
  player2?: RoomPlayer
) => {
  if (step === undefined) return false;
  const isPlayer1 = socket.id === player1?.roomId;
  const isPlayer2 = socket.id === player2?.roomId;
  if (
    (isPlayer1 && step.player === "Player 1") ||
    (isPlayer2 && step.player === "Player 2") ||
    ((isPlayer1 || isPlayer2) && step.player === "Both")
  )
    return true;
  return false;
};

export const formatTimer = (time: number) => {
  let res = "";
  const minutes = Math.floor(time / 60);
  if (minutes > 0) res += minutes + ":";
  const seconds = time % 60;
  if (seconds < 10) res += "0" + seconds;
  else res += seconds;
  return res;
};

export const formatDate = (date: number) => {
  const d = new Date(date);
  const formatTwoDigits = (n: number) => (n < 10 ? `0${n}` : n);
  return `${formatTwoDigits(d.getDate())}/${formatTwoDigits(
    d.getMonth() + 1
  )}/${d.getFullYear()}, ${formatTwoDigits(d.getHours())}:${formatTwoDigits(
    d.getMinutes()
  )}`;
};

export const applyBoxPreferences = (
  box: CharacterBox[],
  preferences?: CharacterBox[]
) => {
  if (preferences === undefined) return box;
  const map = preferences.map((c) => c.name);
  return box.filter((c) => !map.includes(c.name));
};
