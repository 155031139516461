import { AccountCircle, PersonAddAlt1 } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import Avatar from "common/components/Avatar";
import Typography from "common/components/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { RoomPlayer } from "types/Room/type";

type TeamScoreType = {
  score?: number;
  color: "player1" | "player2";
};

const TeamScore = (props: TeamScoreType) => {
  const { t } = useTranslation();
  const { color, score } = props;

  return (
    <Stack
      alignItems="center"
      direction={color === "player1" ? "row" : "row-reverse"}
      width="100%"
      gap={2}
      sx={{
        background: score
          ? ({ palette }) =>
              `linear-gradient(to ${color === "player1" ? "right" : "left"}, ${
                palette.common[color].text
              } 1%, ${palette.common[color].bgConstel} 1% 75%, transparent)`
          : "transparent",
        height: "fit-content",
        padding: 2,
      }}
    >
      {score ? (
        <Stack direction="column" width="100%">
          <Typography
            size="sm"
            textAlign="center"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width="100%"
          >
            {t("Score de l'équipe : {{score}}", {
              score: score ?? "...",
            })}
          </Typography>
        </Stack>
      ) : (
        <Typography size="sm" color="gray">
          {t("En attente de l'équipe...")}
        </Typography>
      )}
    </Stack>
  );
};

export default TeamScore;
