import {
  Card,
  CardMedia,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "service/root/rootApi";
import { Player } from "service/player/type";
import { useTranslation } from "react-i18next";
import { Close, CloudDownload } from "@mui/icons-material";
import Typography from "common/components/Typography";
import { getScore } from "service/draft";

type ComparePlayerCardProps = {
  player?: Player;
  score?: number;
  onDelete: () => void;
  color: "player1" | "player2";
};

const ComparePlayerCard = (props: ComparePlayerCardProps) => {
  const { player, onDelete, color, score } = props;
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: 170,
        width: 140,
        bgcolor: ({ palette }) => palette.common[color].paper,
      }}
    >
      <Stack
        direction="column"
        gap={0.5}
        px={1}
        py={0.5}
        justifyContent={player !== undefined ? "space-between" : "center"}
        alignItems="center"
        height="100%"
      >
        {player === undefined ? (
          <>
            <Typography size="sm" textAlign="center">
              {t("Sélectionne un joueur")}
            </Typography>
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              animation={false}
            />
          </>
        ) : (
          <>
            <Stack justifyContent="space-between" direction="row" width="100%">
              <Tooltip title={t("Télécharger")} arrow>
                <IconButton
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(player.characters)
                  )}`}
                  download={`${player.pseudo}.json`}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
              <IconButton onClick={onDelete}>
                <Close />
              </IconButton>
            </Stack>
            <Typography
              textAlign="center"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              width="8rem"
              size="sm"
              title={player.pseudo}
            >
              {player.pseudo}
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: "4rem",
              }}
              src={`${API_URL}assets/characters/${player.avatar}/avatar.png`}
              title={player.pseudo}
            />
            {score ?? "..."}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default ComparePlayerCard;
