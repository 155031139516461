import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import GenshinButton from "common/components/GenshinButton";
import GenshinDialog from "common/components/GenshinDialog";
import { useErrorNotification } from "common/components/Notification";
import { useSocket } from "common/hooks/useSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSheet } from "service/sheet";

type ConfirmLeaveProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ConfirmLeave = (props: ConfirmLeaveProps) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <GenshinDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("Quitter la page")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Cette feuille contient des modifications non enregistrées, quitter la page annulera tout changement. Es-tu sûr de vouloir continuer ?"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenshinButton onClick={() => setOpen(false)}>
          {t("Annuler")}
        </GenshinButton>
        <GenshinButton color="error" onClick={() => navigate("/balancing")}>
          {t("Quitter")}
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default ConfirmLeave;
