import { useSocket } from "common/hooks/useSocket";
import React, { DragEvent, useContext, useState } from "react";
import {
  Button,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DraftCharacter } from "types/Draft/type";
import Constellation from "common/components/Constellation";
import { StyledTypography } from "pages/Draft/PicksList";
import { DividerVertical } from "assets/icons";
import { API_URL } from "service/root/rootApi";
import { RoomContext } from "App/context";
import { GameMode } from "types/Room/type";

type PicksListType = {
  player: "player1" | "player2";
  picks: DraftCharacter[];
  onDrop?: (order: DraftCharacter[]) => void;
};

const PicksList = (props: PicksListType) => {
  const { params, player3, player4, player1BoxSave, player2BoxSave } =
    useContext(RoomContext);
  const { player, picks, onDrop } = props;
  const is1V1 = params.gameMode === GameMode._1vs1;
  const is2V2 = params.gameMode === GameMode._2vs2;

  const handleDragStart =
    (char: DraftCharacter) => (event: DragEvent<HTMLDivElement>) => {
      let fromBox = JSON.stringify(char);
      event.dataTransfer.setData("dragCharacter", fromBox);
    };

  const handleDragOver = () => (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop =
    (toChar: DraftCharacter) => (event: DragEvent<HTMLDivElement>) => {
      if (onDrop) {
        event.preventDefault();
        let fromChar = JSON.parse(event.dataTransfer.getData("dragCharacter"));

        let tmp = picks.slice();
        let fromIndex =
          picks.findIndex((character) => character.name === fromChar.name) ??
          -1;
        let toIndex =
          picks.findIndex((character) => character.name === toChar.name) ?? -1;

        tmp[fromIndex] = toChar;
        tmp[toIndex] = fromChar;
        onDrop(tmp);
      }
    };

  const renderConstellation = (
    character: DraftCharacter,
    side: keyof DraftCharacter
  ) => {
    if (is1V1) {
      return (
        <Constellation
          player={side === "p1Constellation" ? "player1" : "player2"}
        >
          {character[side]}
        </Constellation>
      );
    } else if (is2V2) {
      if (side === "p1Constellation") {
        const player1Char = player1BoxSave?.find(
          (c) => c.name === character.name
        );
        const player3Char = player3?.box?.find(
          (c) => c.name === character.name
        );
        return (
          <>
            {player1Char && (
              <Constellation player={"player1"}>
                {player1Char.constellation}
              </Constellation>
            )}
            {player3Char && (
              <Constellation player={"player3"}>
                {player3Char.constellation}
              </Constellation>
            )}
          </>
        );
      } else if (side === "p2Constellation") {
        const player2Char = player2BoxSave?.find(
          (c) => c.name === character.name
        );
        const player4Char = player4?.box?.find(
          (c) => c.name === character.name
        );
        return (
          <>
            {player2Char && (
              <Constellation player={"player2"}>
                {player2Char.constellation}
              </Constellation>
            )}
            {player4Char && (
              <Constellation player={"player4"}>
                {player4Char.constellation}
              </Constellation>
            )}
          </>
        );
      }
    }
  };

  return (
    <Stack
      direction="row"
      height="1vh"
      width="100%"
      flexGrow={1}
      gap={1}
      sx={{ svg: { height: "100%" } }}
    >
      {[...Array(9).keys()].map((value) =>
        value === 4 ? (
          <DividerVertical key={"-1"} />
        ) : (
          <Stack
            key={value}
            sx={{
              position: "relative",
              borderRadius: "1rem",
              overflow: "hidden",
              width: "100%",
              border: ({ palette }) =>
                `1px solid ${palette.common[player].borderConstel}`,
            }}
          >
            <CardMedia
              key={value}
              component="img"
              src={`${API_URL}assets/characters/${
                picks[value > 4 ? value - 1 : value].name
              }/drip.png`}
              {...(onDrop !== undefined
                ? {
                    draggable: true,
                    onDragStart: handleDragStart(
                      picks[value > 4 ? value - 1 : value]
                    ),
                    onDragOver: handleDragOver(),
                    onDrop: handleDrop(picks[value > 4 ? value - 1 : value]),
                  }
                : {})}
              sx={{
                borderRadius: "1rem",
                objectPosition: "90% 10%",
                animation: "fadeIn 0.7s linear",
                animationDelay: `${0.15 * (value + 1)}s`,
                animationFillMode: "both",
                cursor: onDrop !== undefined ? "move" : "unset",
                "@keyframes fadeIn": {
                  "0%": {
                    opacity: 0,
                    transform: "scale(0.1)",
                  },
                  "85%": {
                    opacity: 1,
                    transform: "scale(1.5)",
                  },
                  "100%": {
                    transform: "scale(1.35)",
                  },
                },
              }}
            />
            {renderConstellation(
              picks[value > 4 ? value - 1 : value],
              player === "player1" ? "p1Constellation" : "p2Constellation"
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};

export default PicksList;
