import {
  Button,
  CardMedia,
  Dialog,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { RoomContext } from "App/context";
import GenshinButton from "common/components/GenshinButton";
import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type FlipCoinProps = {
  display: boolean;
};

const StyledButton = styled(Button)({
  position: "relative",
  height: "100%",
  transition: "transform 1s ease-in",
  transformStyle: "preserve-3d",
  borderRadius: "100%",
});

const FlipCoin = (props: FlipCoinProps) => {
  const { display } = props;
  const { player1, player2, admin } = useContext(RoomContext);
  const socket = useSocket();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [key, setKey] = useState<number>(new Date().getTime());
  const [face, setFace] = useState<"heads" | "tails" | "">("");

  const tossCoin = () => {
    if (Math.random() < 0.5) socket.emit("send-heads-or-tails-result", "heads");
    else socket.emit("send-heads-or-tails-result", "tails");
  };

  const subscribeEvents = () => {
    socket.on("show-heads-or-tails", () => setOpen(true));
    socket.on("heads-or-tails-result", (face: "heads" | "tails") => {
      setFace(face);
      setKey(new Date().getTime());
    });
    socket.on("close-heads-or-tails", () => setOpen(false));
  };

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("show-heads-or-tails");
      socket.removeListener("close-heads-or-tails");
      socket.removeListener("heads-or-tails-result");
    };
  }, []);

  const getAnimation = (): SxProps => {
    if (face === "heads") {
      return {
        "@keyframes flipHeads": {
          from: {
            transform: "rotateY(0)",
          },
          to: {
            transform: "rotateY(1260deg)",
          },
        },
        animation: "flipHeads 2s ease-out forwards",
      };
    } else if (face === "tails") {
      return {
        "@keyframes flipTails": {
          from: {
            transform: "rotateY(0)",
          },
          to: {
            transform: "rotateY(1440deg)",
          },
        },
        animation: "flipTails 2s ease-out forwards",
      };
    }
    return {};
  };

  useEffect(() => {
    if (open) setFace("");
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (admin) socket.emit("on-close-heads-or-tails");
        }}
        sx={{
          ".MuiPaper-root": {
            background: "unset",
            width: "184px",
            height: "184px",
            boxShadow: "unset",
          },
        }}
      >
        <StyledButton
          onClick={() => tossCoin()}
          disabled={!admin}
          sx={getAnimation()}
          key={key}
        >
          <CardMedia
            component="img"
            src={require("assets/images/heads.png")}
            sx={{
              zIndex: 100,
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          />
          <CardMedia
            component="img"
            src={require("assets/images/tails.png")}
            sx={{
              transform: "rotateY(-180deg)",
              position: "absolute",
              backfaceVisibility: "hidden",
            }}
          />
        </StyledButton>
      </Dialog>
      {admin && display && (
        <Stack direction="column" alignItems="center">
          <GenshinButton
            onClick={() => {
              if (admin) socket.emit("on-click-heads-or-tails");
            }}
          >
            {t("Pile ou face")}
          </GenshinButton>
        </Stack>
      )}
    </>
  );
};

export default FlipCoin;
