import { CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import GenshinButton from "common/components/GenshinButton";
import GenshinDialog from "common/components/GenshinDialog";
import { useSocket } from "common/hooks/useSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type ConfirmReplayProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  switchPlayers: boolean;
  setSwitchPlayers: (value: boolean) => void;
};

const ConfirmReplay = (props: ConfirmReplayProps) => {
  const { open, setOpen, switchPlayers, setSwitchPlayers } = props;
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();

  return (
    <GenshinDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("Rejouer")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Rejouer ramènera tout le monde dans la salle d'attente. Voulez-vous continuer ?"
          )}
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={switchPlayers}
              onChange={(val) => setSwitchPlayers(val.target.checked)}
            />
          }
          label={t(
            "Continuer avec les mêmes joueurs et inverser la configuration."
          )}
          sx={{ marginTop: "1rem" }}
        />
      </DialogContent>
      <DialogActions>
        <GenshinButton onClick={() => setOpen(false)}>
          {t("Annuler")}
        </GenshinButton>
        <GenshinButton
          onClick={() => {
            socket.emit("send-replay", switchPlayers);
          }}
        >
          {t("Rejouer")}
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default ConfirmReplay;
