import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { useSocket } from "common/hooks/useSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PreparationTimeProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  time: string;
};

const PreparationTime = (props: PreparationTimeProps) => {
  const { open, setOpen, time } = props;
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogContent>
        <DialogContentText fontSize="12rem" textAlign="center">
          {time}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PreparationTime;
