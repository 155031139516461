import React, { PropsWithChildren } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { palettes } from "common/theming";

type ConstellationType = {
  player: "player1" | "player2" | "player3" | "player4";
};

const player1Props: SxProps = {
  top: "-1px",
  left: "-1px",
  borderBottomRightRadius: "0.7rem",
  bgcolor: palettes.dark.common?.player1?.bgConstel,
  borderColor: palettes.dark.common?.player1?.borderConstel,
};
const player2Props: SxProps = {
  top: "-1px",
  right: "-1px",
  borderBottomLeftRadius: "0.7rem",
  bgcolor: palettes.dark.common?.player2?.bgConstel,
  borderColor: palettes.dark.common?.player2?.borderConstel,
};
const player3Props: SxProps = {
  top: "29px",
  left: "-1px",
  borderTopRightRadius: "0.7rem",
  borderBottomRightRadius: "0.7rem",
  bgcolor: palettes.dark.common?.player1?.bgConstel,
  borderColor: palettes.dark.common?.player1?.borderConstel,
};
const player4Props: SxProps = {
  top: "29px",
  right: "-1px",
  borderTopLeftRadius: "0.7rem",
  borderBottomLeftRadius: "0.7rem",
  bgcolor: palettes.dark.common?.player2?.bgConstel,
  borderColor: palettes.dark.common?.player2?.borderConstel,
};

const Constellation = (props: PropsWithChildren<ConstellationType>) => {
  const { player } = props;

  const getProps = () => {
    switch (player) {
      case "player1":
        return player1Props;
      case "player2":
        return player2Props;
      case "player3":
        return player3Props;
      case "player4":
        return player4Props;
    }
  };

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "-1px",
        zIndex: 1,
        left: player === "player1" ? "-1px" : "unset",
        right: player === "player2" ? "-1px" : "unset",
        borderBottomRightRadius: player === "player1" ? "0.7rem" : "unset",
        borderBottomLeftRadius: player === "player2" ? "0.7rem" : "unset",
        width: 30,
        height: 30,
        color: "white",
        border: "1px solid",
        ...getProps(),
      }}
    >
      <Typography>{props.children}</Typography>
    </Box>
  );
};

export default Constellation;
