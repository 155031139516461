import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { GameMode, Room } from "types/Room/type";
import React, { PropsWithChildren, createContext } from "react";
import { Player } from "service/player/type";
import { Character } from "types/Character/type";
import { Sheet } from "service/sheet/type";

type RoomSocket = Room & {
  localPlayer?: Player;
  charactersList: Character[];
  availableSheets: Sheet[];
  admin: boolean;
  konami: boolean;
};

type RoomContextType = RoomSocket & {
  dispatch: (action: Partial<RoomSocket>) => void;
};

export const defaultValues: RoomContextType = {
  localPlayer: undefined,
  params: {
    name: "",
    sheet: {
      name: "",
      id: 0,
    },
    gameMode: GameMode._1vs1,
  },
  draft: { banShared: false, pickShared: false, timer: 0, steps: [] },
  spectators: [],
  dispatch: () => {},
  player1: undefined,
  player2: undefined,
  player3: undefined,
  player4: undefined,
  player1BoxSave: undefined,
  player2BoxSave: undefined,
  balancingBansPlayer1: undefined,
  jokerBansPlayer1: undefined,
  balancingBansPlayer2: undefined,
  jokerBansPlayer2: undefined,
  admin: false,
  konami: false,
  charactersList: [],
  availableSheets: [],
};
export const RoomContext = createContext<RoomContextType>(defaultValues);

export const RoomProvider = (
  props: PropsWithChildren<Partial<RoomContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<RoomContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <RoomContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </RoomContext.Provider>
  );
};
