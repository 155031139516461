import React, { ReactNode, useContext } from "react";
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import {
  Anemo,
  Cryo,
  Dendro,
  Electro,
  Geo,
  Hydro,
  Icon4Star,
  Icon5Star,
  Pyro,
  SortConstelDesc,
} from "assets/icons";
import { ElementType } from "types/Character/type";
import { Backspace, FilterAltOff } from "@mui/icons-material";
import { FilterContext } from "../context";
import { useTranslation } from "react-i18next";
import Typography from "common/components/Typography";

export const ELEMENTS: { [key in ElementType]: ReactNode } = {
  Pyro: <Pyro />,
  Hydro: <Hydro />,
  Anemo: <Anemo />,
  Electro: <Electro />,
  Dendro: <Dendro />,
  Cryo: <Cryo />,
  Geo: <Geo />,
};

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type), .MuiToggleButtonGroup-grouped:not(:first-of-type)":
    {
      borderRadius: "0.5rem",
    },
  "& .MuiToggleButton-root": {
    border: "none",
    padding: "0.25rem",
    height: "fit-content",
  },
  "& .MuiSvgIcon-root": {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

type FilteringBarProps = {
  mode?: "balancing";
};

const FilteringBar = (props: FilteringBarProps) => {
  const { mode = undefined } = props;
  const { t } = useTranslation();
  const {
    element,
    rarity,
    player,
    search,
    sortConstelDesc,
    linesWithValues,
    dispatch,
  } = useContext(FilterContext);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <ToggleButton
        value={"sortConstelDesc"}
        selected={sortConstelDesc}
        onClick={() => dispatch({ sortConstelDesc: !sortConstelDesc })}
        sx={{ visibility: mode === "balancing" ? "hidden" : "visible" }}
      >
        <SortConstelDesc />
      </ToggleButton>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <IconButton
          onClick={() =>
            dispatch({
              sortConstelDesc: false,
              linesWithValues: true,
              element: null,
              rarity: null,
              player: null,
              search: "",
            })
          }
        >
          <FilterAltOff />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        <ToggleButtonGroupStyled
          exclusive
          value={element}
          onChange={(_, newElement) => dispatch({ element: newElement })}
        >
          {Object.keys(ELEMENTS).map((element) => (
            <ToggleButton value={element} key={element}>
              {ELEMENTS[element]}
            </ToggleButton>
          ))}
        </ToggleButtonGroupStyled>
        <Divider orientation="vertical" flexItem />
        <ToggleButtonGroupStyled
          exclusive
          value={rarity}
          onChange={(_, newRarity) => dispatch({ rarity: newRarity })}
        >
          <ToggleButton value={4}>{<Icon4Star />}</ToggleButton>
          <ToggleButton value={5}>{<Icon5Star />}</ToggleButton>
        </ToggleButtonGroupStyled>
        <Divider orientation="vertical" flexItem />
        {mode === "balancing" ? (
          <FormControlLabel
            sx={{ gap: "0.5rem", margin: 0 }}
            control={
              <Switch
                checked={linesWithValues}
                onChange={(evt) =>
                  dispatch({ linesWithValues: evt.target.checked })
                }
              />
            }
            label={t("Lignes avec valeurs")}
          />
        ) : (
          <ToggleButtonGroupStyled
            exclusive
            value={player}
            onChange={(_, newPlayer) => dispatch({ player: newPlayer })}
          >
            <ToggleButton value={"Player 1"}>
              <Typography
                size="sm"
                sx={{ color: ({ palette }) => palette.common.player1.text }}
              >
                {t("J{{number}}", { number: 1 })}
              </Typography>
            </ToggleButton>
            <ToggleButton value={"Player 2"}>
              <Typography
                size="sm"
                sx={{ color: ({ palette }) => palette.common.player2.text }}
              >
                {t("J{{number}}", { number: 2 })}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroupStyled>
        )}
        <Divider orientation="vertical" flexItem />
        <FormControl variant="outlined" size="small">
          <InputLabel>{t("Chercher")}</InputLabel>
          <OutlinedInput
            value={search}
            onChange={(event) =>
              dispatch({
                sortConstelDesc: false,
                element: null,
                rarity: null,
                player: null,
                search: event.target.value,
              })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => dispatch({ search: "" })}>
                  <Backspace />
                </IconButton>
              </InputAdornment>
            }
            label={t("Chercher")}
          />
        </FormControl>
      </Stack>
      <Stack />
    </Stack>
  );
};

export default FilteringBar;
