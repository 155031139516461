import { Badge, Grid, Stack } from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { RoomContext } from "App/context";
import { Link, useNavigate } from "react-router-dom";
import { useSocket } from "common/hooks/useSocket";
import { GameMode, Room, RoomPlayer } from "types/Room/type";
import SelectPlayer from "./SelectPlayer";
import SpectatorsList from "./SpectatorsList";
import { useTranslation } from "react-i18next";
import SkewButton from "common/components/SkewButton";
import GenshinButton from "common/components/GenshinButton";
import BalancingBans from "./BalancingBans";
import {
  BalancingBansContext,
  BalancingBansProvider,
} from "./BalancingBans/context";
import { BannedCharacter, SelectingBan } from "pages/Draft/BansList";
import FlipCoin from "./FlipCoin";
import FirstPartBansSelector from "./FirstPartBansSelector";
import { JokerBansContext, JokerBansProvider } from "./JokerBans/context";
import JokerBans from "./JokerBans";
import { DraftParams } from "service/draft/type";
import { getDraftParams, getScore } from "service/draft";
import ParamsDialog from "./ParamsDialog";
import TeamScore from "./TeamScore";

const WaitingRoom = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();
  const {
    spectators,
    dispatch,
    admin,
    player1,
    player2,
    player3,
    player4,
    balancingBansPlayer1,
    jokerBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer2,
    params,
    player1BoxSave,
    player2BoxSave,
  } = useContext(RoomContext);
  const {
    dispatch: dispatchBalancingBansContext,
    selectingBans: selectingBalancingBans,
    selectedBansPlayer1: selectedBalancingBansPlayer1,
    selectedBansPlayer2: selectedBalancingBansPlayer2,
  } = useContext(BalancingBansContext);
  const {
    dispatch: dispatchJokerBansContext,
    selectingBans: selectingJokerBans,
    selectedBansPlayer1: selectedJokerBansPlayer1,
    selectedBansPlayer2: selectedJokerBansPlayer2,
  } = useContext(JokerBansContext);
  const [openParams, setOpenParams] = useState<boolean>(false);
  const [draftParams, setDraftParams] = useState<DraftParams>();
  const [scoreP1, setScoreP1] = useState<number | undefined>();
  const [scoreP2, setScoreP2] = useState<number | undefined>();
  const [scoreP3, setScoreP3] = useState<number | undefined>();
  const [scoreP4, setScoreP4] = useState<number | undefined>();
  const [scoreP1AndP3, setScoreP1AndP3] = useState<number | undefined>();
  const [scoreP2AndP4, setScoreP2AndP4] = useState<number | undefined>();
  const is1VS1 = params.gameMode === GameMode._1vs1;
  const is2VS2 = params.gameMode === GameMode._2vs2;
  const renderBansButtons =
    player1 !== undefined &&
    player2 !== undefined &&
    (is1VS1 || (is2VS2 && player3 !== undefined && player4 !== undefined));

  const subscribeEvents = () => {
    socket.on("start-draft", () => navigate("/draft"));
    socket.on("update-room", (room: Room) => {
      dispatch({
        ...room,
        player1: room.player1 ?? undefined,
        player2: room.player2 ?? undefined,
        player3: room.player3 ?? undefined,
        player4: room.player4 ?? undefined,
        player1BoxSave: room.player1BoxSave ?? undefined,
        player2BoxSave: room.player2BoxSave ?? undefined,
        balancingBansPlayer1: room.balancingBansPlayer1 ?? undefined,
        jokerBansPlayer1: room.jokerBansPlayer1 ?? undefined,
        balancingBansPlayer2: room.balancingBansPlayer2 ?? undefined,
        jokerBansPlayer2: room.jokerBansPlayer2 ?? undefined,
      });
    });
    socket.on("balanced-ban", (room: Room) => {
      dispatch({
        ...room,
      });
      dispatchBalancingBansContext({
        selectingBans: undefined,
        selectedBansPlayer1: undefined,
        selectedBansPlayer2: undefined,
      });
    });
    socket.on("joker-ban", (room: Room) => {
      dispatch({
        ...room,
      });
      dispatchJokerBansContext({
        selectingBans: undefined,
        selectedBansPlayer1: undefined,
        selectedBansPlayer2: undefined,
      });
    });
    socket.on("admin-left", () => {
      navigate("/");
    });
  };

  const updateScore = async (
    box: RoomPlayer["box"],
    setScore: React.Dispatch<React.SetStateAction<number | undefined>>
  ) => {
    setScore(await getScore(box ?? [], params.sheet.id));
  };

  useEffect(() => {
    subscribeEvents();
    socket.emit("get-room");

    return () => {
      socket.removeListener("start-draft");
      socket.removeListener("update-room");
      socket.removeListener("balanced-ban");
      socket.removeListener("admin-left");
    };
  }, []);

  useEffect(() => {
    if (player1 && scoreP1 === undefined && player1BoxSave === undefined)
      updateScore(player1.box, setScoreP1);
    if (player1 === undefined) setScoreP1(undefined);
  }, [player1]);
  useEffect(() => {
    if (player2 && scoreP2 === undefined && player2BoxSave === undefined)
      updateScore(player2.box, setScoreP2);
    if (player2 === undefined) setScoreP2(undefined);
  }, [player2]);
  useEffect(() => {
    if (player3 && scoreP3 === undefined) updateScore(player3.box, setScoreP3);
    else if (player3 === undefined) setScoreP3(undefined);
  }, [player3]);
  useEffect(() => {
    if (player4 && scoreP4 == undefined) updateScore(player4.box, setScoreP4);
    else if (player4 === undefined) setScoreP4(undefined);
  }, [player4]);

  useEffect(() => {
    if (is1VS1 && scoreP1 !== undefined && scoreP2 !== undefined) {
      const checkDraftParams = async () => {
        if (player1?.box !== undefined && player2?.box !== undefined) {
          setDraftParams(
            await getDraftParams(player1.box, player2.box, params.sheet.id)
          );
        }
      };
      checkDraftParams();
    } else if (
      is2VS2 &&
      scoreP1AndP3 !== undefined &&
      scoreP2AndP4 !== undefined
    ) {
      const checkDraftParams = async () => {
        if (player1?.box !== undefined && player2?.box !== undefined) {
          setDraftParams(
            await getDraftParams(player1.box, player2.box, params.sheet.id)
          );
        }
      };
      checkDraftParams();
    } else setDraftParams(undefined);
  }, [scoreP1, scoreP2, scoreP1AndP3, scoreP2AndP4]);

  useEffect(() => {
    if (player1 && player3) {
      updateScore(player1.box, setScoreP1AndP3);
      if (scoreP1 === undefined) updateScore(player1BoxSave, setScoreP1);
    } else setScoreP1AndP3(undefined);
  }, [player1, player3]);
  useEffect(() => {
    if (player2 && player4) {
      updateScore(player2.box, setScoreP2AndP4);
      if (scoreP2 === undefined) updateScore(player2BoxSave, setScoreP2);
    } else setScoreP2AndP4(undefined);
  }, [player2, player4]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      height="100%"
      flexWrap="nowrap"
      sx={{
        backgroundImage: "linear-gradient(#000B, #000B), url(/got.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Typography size="xl">{t("Salle d'attente")}</Typography>
      <Grid item height="100%" width="100%" flexGrow={1}>
        <Stack
          direction="row"
          gap={3}
          gridTemplateColumns="23rem auto 23rem"
          display="grid"
          padding={1}
          paddingTop="0"
          height="100%"
        >
          <Stack direction="column" justifyContent="space-between">
            <Stack gap={1}>
              {is2VS2 && <TeamScore score={scoreP1AndP3} color="player1" />}
              <SelectPlayer
                player={player1}
                color={"player1"}
                score={scoreP1}
                onClick={() => socket.emit("select-player1")}
              />
              {is2VS2 && (
                <SelectPlayer
                  player={player3}
                  color={"player1"}
                  score={scoreP3}
                  onClick={() => socket.emit("select-player3")}
                />
              )}
              {(selectedBalancingBansPlayer1 || balancingBansPlayer1) && (
                <Typography size="sm">{t("Bans d'équilibrage :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedBalancingBansPlayer1?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {balancingBansPlayer1 &&
                  balancingBansPlayer1.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
              {(selectedJokerBansPlayer1 || jokerBansPlayer1) && (
                <Typography size="sm">{t("Bans joker :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedJokerBansPlayer1?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {jokerBansPlayer1 &&
                  jokerBansPlayer1.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
            </Stack>
            <Link to={"/"}>
              <GenshinButton variant="contained">{t("Quitter")}</GenshinButton>
            </Link>
          </Stack>

          <Stack direction="column" alignItems="center" gap={1}>
            <GenshinButton onClick={() => setOpenParams(true)} sx={{ mb: 0.5 }}>
              {t("Paramètres du salon")}
            </GenshinButton>
            <Badge color={"warning"} badgeContent={draftParams?.firstPartBans}>
              <FirstPartBansSelector />
            </Badge>
            <FlipCoin display={!renderBansButtons} />
            <Stack
              direction="row"
              height="100%"
              gap={1}
              alignItems="center"
              width={
                selectedJokerBansPlayer1 !== undefined ||
                selectedJokerBansPlayer2 !== undefined ||
                selectedBalancingBansPlayer1 !== undefined ||
                selectedBalancingBansPlayer2 !== undefined
                  ? "100%"
                  : undefined
              }
            >
              {renderBansButtons &&
                selectedJokerBansPlayer1 === undefined &&
                selectedJokerBansPlayer2 === undefined && (
                  <BalancingBans
                    badgeQuantity={draftParams?.balancingBans}
                    whoBan={
                      (draftParams?.balancingBans ?? 0) < 0
                        ? "player1"
                        : "player2"
                    }
                  />
                )}
              {renderBansButtons &&
                selectedBalancingBansPlayer1 === undefined &&
                selectedBalancingBansPlayer2 === undefined && (
                  <JokerBans
                    badgeQuantity={draftParams?.jokerBans}
                    whoBan={
                      (draftParams?.jokerBans ?? 0) < 0 ? "player1" : "player2"
                    }
                  />
                )}
            </Stack>
            {admin && (
              <SkewButton
                variant="contained"
                disabled={
                  player1 === undefined ||
                  player2 === undefined ||
                  selectingBalancingBans !== undefined ||
                  selectingJokerBans !== undefined
                }
                onClick={() => socket.emit("send-start-draft")}
                sx={{
                  width: "fit-content",
                  margin: "auto",
                  padding: "0.75rem 5rem",
                }}
              >
                {t("Commencer")}
              </SkewButton>
            )}
          </Stack>

          <Stack direction="column" justifyContent="space-between">
            <Stack gap={1}>
              {is2VS2 && <TeamScore score={scoreP2AndP4} color="player2" />}
              <SelectPlayer
                player={player2}
                color={"player2"}
                score={scoreP2}
                onClick={() => socket.emit("select-player2")}
              />
              {is2VS2 && (
                <SelectPlayer
                  player={player4}
                  color={"player2"}
                  score={scoreP4}
                  onClick={() => socket.emit("select-player4")}
                />
              )}
              {(selectedBalancingBansPlayer2 || balancingBansPlayer2) && (
                <Typography size="sm">{t("Bans d'équilibrage :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedBalancingBansPlayer2?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {balancingBansPlayer2 &&
                  balancingBansPlayer2.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
              {(selectedJokerBansPlayer2 || jokerBansPlayer2) && (
                <Typography size="sm">{t("Bans joker :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedJokerBansPlayer2?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {jokerBansPlayer2 &&
                  jokerBansPlayer2.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
            </Stack>
            <SpectatorsList
              spectators={spectators}
              onClick={() => socket.emit("select-spectator")}
            />
          </Stack>
        </Stack>
      </Grid>
      <ParamsDialog open={openParams} onClose={() => setOpenParams(false)} />
    </Grid>
  );
};

const Wrapper = () => {
  return (
    <BalancingBansProvider>
      <JokerBansProvider>
        <WaitingRoom />
      </JokerBansProvider>
    </BalancingBansProvider>
  );
};

export default Wrapper;
