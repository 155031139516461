import { useSocket } from "common/hooks/useSocket";
import React, { useContext } from "react";
import { DraftContext } from "../context";
import {
  CardMedia,
  Divider,
  IconButton,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import { DraftCharacter, DraftStep } from "types/Draft/type";
import { Close, DoNotDisturb } from "@mui/icons-material";
import Constellation from "common/components/Constellation";
import { API_URL } from "service/root/rootApi";

type BansListProps = {
  color: string;
  banSteps: DraftStep[];
  bans: DraftCharacter[];
  hiddenBans?: DraftCharacter[];
  selected?: DraftCharacter[];
  hideBans?: boolean;
  onClick?: (character: DraftCharacter) => void;
};

export const BanPaperStyled = styled(Paper)(({ theme }) => ({
  width: "85px",
  height: "85px",
}));
export const PulsingBanPaper = styled(BanPaperStyled)(({ theme }) => ({
  position: "relative",
  "@keyframes pulse-ban": {
    "0%, 100%": {
      backgroundColor: "transparent",
    },
    "50%": {
      backgroundColor: theme.palette.common.player2.banCard,
    },
  },
  animation: "pulse-ban 2s infinite",
}));
export const CardMediaBanStyled = styled(
  ({ characterName, ...props }: { characterName: string }) => (
    <CardMedia
      component="img"
      src={`${API_URL}assets/characters/${characterName}/thumb.png`}
      {...props}
    />
  )
)(() => ({
  maxHeight: "100%",
  borderRadius: "0.5rem",
}));

export const SelectingBan = (props: { character: DraftCharacter }) => {
  const { character } = props;
  return (
    <BanPaperStyled
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: ({ palette }) => palette.common.player2.banCard,
      }}
    >
      {character.p1Constellation !== undefined && (
        <Constellation player="player1">
          {character.p1Constellation}
        </Constellation>
      )}
      {character.p2Constellation !== undefined && (
        <Constellation player="player2">
          {character.p2Constellation}
        </Constellation>
      )}
      <CardMediaBanStyled characterName={character.name} />
    </BanPaperStyled>
  );
};
export const BannedCharacter = (props: {
  character: DraftCharacter;
  size?: "medium" | "small";
}) => {
  const { character, size } = props;
  return (
    <BanPaperStyled
      sx={{
        position: "relative",
        overflow: "hidden",
        width: size === "small" ? "70px" : "85px",
        height: size === "small" ? "70px" : "85px",
      }}
    >
      {character.p1Constellation !== undefined && (
        <Constellation player="player1">
          {character.p1Constellation}
        </Constellation>
      )}
      {character.p2Constellation !== undefined && (
        <Constellation player="player2">
          {character.p2Constellation}
        </Constellation>
      )}
      <CardMediaBanStyled characterName={character.name} />
    </BanPaperStyled>
  );
};

const BansList = (props: BansListProps) => {
  const {
    color,
    banSteps,
    bans,
    hiddenBans,
    selected,
    hideBans = false,
    onClick,
  } = props;
  const socket = useSocket();
  const { currentStep } = useContext(DraftContext);

  const getCharacterCard = (iStep: number, iQuantity: number) => {
    const index =
      banSteps
        .slice(0, iStep)
        .reduce((acc, { quantity }) => acc + quantity, 0) + iQuantity;

    if (bans[index] !== undefined) {
      return (
        <BannedCharacter
          key={`${color}-${iStep}-${iQuantity}`}
          character={bans[index]}
        />
      );
    } else if (hiddenBans !== undefined && hiddenBans[index] !== undefined) {
      const character = hiddenBans[index];
      return (
        <BanPaperStyled key={`${color}-${iStep}-${iQuantity}`}>
          <CardMediaBanStyled
            characterName={hideBans ? "Voyageur" : character.name}
            sx={{
              filter: hideBans ? "grayscale(1)" : "none",
            }}
          />
        </BanPaperStyled>
      );
    } else if (
      selected !== undefined &&
      index - bans.length < (currentStep?.quantity ?? 0)
    ) {
      const character = selected[index - bans.length];
      return (
        <PulsingBanPaper key={`${color}-${iStep}-${iQuantity}`}>
          {character !== undefined ? (
            <>
              <Stack
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "12px",
                  position: "absolute",
                  overflow: "hidden",
                }}
              >
                {!hideBans && character.p1Constellation !== undefined && (
                  <Constellation player="player1">
                    {character.p1Constellation}
                  </Constellation>
                )}
                {!hideBans && character.p2Constellation !== undefined && (
                  <Constellation player="player2">
                    {character.p2Constellation}
                  </Constellation>
                )}
              </Stack>
              <CardMediaBanStyled
                characterName={hideBans ? "Voyageur" : character.name}
                sx={{
                  filter: hideBans ? "grayscale(1)" : "none",
                }}
              />
              {onClick && (
                <Stack
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    bottom: "-14px",
                    width: "100%",
                  }}
                >
                  <IconButton
                    onClick={() => onClick(character)}
                    sx={{
                      padding: "2px",
                      margin: "auto",
                      bgcolor: ({ palette }) => palette.common.player2.banCard,
                      "&:hover": {
                        bgcolor: ({ palette }) => palette.common.player2.paper,
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              )}
            </>
          ) : (
            ""
          )}
        </PulsingBanPaper>
      );
    } else {
      return (
        <BanPaperStyled
          key={`${color}-${iStep}-${iQuantity}`}
          sx={{ padding: "0.5rem" }}
        >
          <DoNotDisturb sx={{ width: "100%", height: "100%" }} />
        </BanPaperStyled>
      );
    }
  };

  return (
    <Stack direction="row" gap={1} padding={1}>
      {banSteps.map((step, iStep) => (
        <Stack key={`${color}-${iStep}`} direction="row" gap={1}>
          {[...Array(step.quantity).keys()].map((iQuantity) =>
            getCharacterCard(iStep, iQuantity)
          )}
          {banSteps.length > 1 && iStep !== banSteps.length - 1 ? (
            <Divider key={`divider-${iStep}`} orientation="vertical" />
          ) : (
            ""
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default BansList;
