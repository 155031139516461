import { Box, Button, Card, CardMedia, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "service/root/rootApi";
import { Player } from "service/player/type";
import { useTranslation } from "react-i18next";
import Typography from "common/components/Typography";
import { getScore } from "service/draft";

type PlayerCardProps = {
  player: Player;
  selected: boolean;
  onClick: () => void;
};

const PlayerCard = (props: PlayerCardProps) => {
  const { player, selected, onClick } = props;
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: 130,
        width: 130,
        px: "0.25rem",
        bgcolor: ({ palette }) =>
          selected ? palette.background.pick : "transparent",
      }}
    >
      <Box
        sx={{
          height: "100%",
          borderRadius: "0.75rem",
          bgcolor: ({ palette }) => palette.background.dark,
        }}
      >
        <Button
          title={player.pseudo}
          sx={{
            height: "100%",
            width: "100%",
            padding: 0,
            py: "0.5rem",
            bgcolor: ({ palette }) => palette.background.dark,
            color: "white",
          }}
          onClick={onClick}
        >
          <Stack
            height="100%"
            borderRadius="0.5rem"
            gap={1}
            alignItems="center"
          >
            <Typography
              textAlign="center"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              width="6rem"
              size="sm"
            >
              {player.pseudo}
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: "5rem",
                filter: !selected ? "grayscale(100%)" : "none",
              }}
              src={`${API_URL}assets/characters/${player.avatar}/avatar.png`}
              title={player.pseudo}
            />
          </Stack>
        </Button>
      </Box>
    </Card>
  );
};

export default PlayerCard;
