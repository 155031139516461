import {
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Typography from "common/components/Typography";
import React from "react";
import GenshinButton from "common/components/GenshinButton";
import { DividerHorizontal, DividerLeft, DividerRight } from "assets/icons";
import GenshinDialog from "common/components/GenshinDialog";
import { Link } from "react-router-dom";

type UnauthorizedDialogProps = {
  open: boolean;
};

const UnauthorizedDialog = (props: UnauthorizedDialogProps) => {
  return (
    <GenshinDialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          gap={1}
          sx={{ svg: { width: "15rem" } }}
        >
          <DividerRight />
          <Typography>
            Accès non authorisé
            <br />
            Unauthorized access
          </Typography>
          <DividerLeft />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ svg: { width: "100%" } }}>
        Pour avoir les authorisations nécessaires pour utiliser le site de
        l'Arène des Abysses, rejoins notre Discord en cliquant sur ce bouton et
        prend le rôle "Participer au PVP Genshin".
        <br />
        <DividerHorizontal />
        <br />
        To have the necessary authorizations to use the site of The Abyss Arena,
        join our discord by clicking on this button and takes the role
        "Participer au PVP Genshin".
        <Stack alignItems="center" width="100%" marginTop="2rem">
          <Link to="https://discord.com/invite/9nKbxnzRep" target={"_blank"}>
            <CardMedia
              component="img"
              src={require("assets/images/join-discord.png")}
              sx={{
                width: "15rem",
              }}
            />
          </Link>
        </Stack>
      </DialogContent>
      <DialogActions>
        <GenshinButton href={process.env.REACT_APP_LOGIN_URL}>
          Relogin
        </GenshinButton>
        <GenshinButton href={process.env.REACT_APP_HOME_URL}>
          Menu
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default UnauthorizedDialog;
