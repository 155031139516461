import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import GenshinButton from "common/components/GenshinButton";
import GenshinDialog from "common/components/GenshinDialog";
import { useErrorNotification } from "common/components/Notification";
import { useSocket } from "common/hooks/useSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSheet } from "service/sheet";

type ConfirmDeleteProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ConfirmDelete = (props: ConfirmDeleteProps) => {
  const { open, setOpen } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatchError = useErrorNotification();
  const socket = useSocket();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await deleteSheet(id);
      navigate("/balancing");
    } catch (error) {
      dispatchError(error);
      setOpen(false);
    }
  };

  return (
    <GenshinDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("Supprimer la feuille")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Es-tu sûr de vouloir supprimer cette feuille ? Cette action est irréversible."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenshinButton onClick={() => setOpen(false)}>
          {t("Annuler")}
        </GenshinButton>
        <GenshinButton color="error" onClick={() => handleDelete()}>
          {t("Supprimer")}
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default ConfirmDelete;
