import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  useTheme,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

type TypographyProps = MuiTypographyProps & {
  size?: "xl" | "m" | "sm";
};

const Typography = (props: PropsWithChildren<TypographyProps>) => {
  const { size = "m" } = props;
  const theme = useTheme();
  return (
    <MuiTypography
      fontSize={`${size === "xl" ? 3.5 : size === "m" ? 1.5 : 1}rem`}
      color={theme.palette.common.white}
      {...props}
    >
      {props.children}
    </MuiTypography>
  );
};

export default Typography;
