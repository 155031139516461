import { Sheet } from "service/sheet/type";
import { Draft, DraftCharacter } from "types/Draft/type";
import { CharacterBox, Player } from "types/Player/type";

export type Room = {
  params: RoomParams;
  draft: Draft;
  player1?: RoomPlayer;
  player2?: RoomPlayer;
  player3?: RoomPlayer;
  player4?: RoomPlayer;
  player1BoxSave?: CharacterBox[];
  player2BoxSave?: CharacterBox[];
  spectators: RoomPlayer[];
  balancingBansPlayer1?: DraftCharacter[];
  jokerBansPlayer1?: DraftCharacter[];
  balancingBansPlayer2?: DraftCharacter[];
  jokerBansPlayer2?: DraftCharacter[];
};

export type Role = "Player 1" | "Player 2";

export type RoomPlayer = Player & {
  roomId: string;
};

export enum GameMode {
  _1vs1 = "1vs1",
  _2vs2 = "2vs2",
}

export const GameModeDictionary: { [key in GameMode]: string } = {
  [GameMode._1vs1]: "1 vs 1",
  [GameMode._2vs2]: "2 vs 2",
};

export type RoomParams = {
  name: string;
  sheet: Pick<Sheet, "name" | "id">;
  gameMode: GameMode;
};
