import {
  Badge,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { RoomContext } from "App/context";
import React, { PropsWithChildren, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../../common/components/Typography";
import { Link, useLocation } from "react-router-dom";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { Player } from "types/Player/type";
import Avatar from "common/components/Avatar";
import AvatarSelector from "../AvatarSelector";

type NavbarProps = {};

const ImgLink = (props: {
  link: string;
  image: string;
  newTab?: boolean;
  size?: "small" | "big";
}) => {
  const { link, image, newTab = false, size = "small" } = props;
  return (
    <Link to={link} target={newTab ? "_blank" : undefined}>
      <CardMedia
        component="img"
        src={image}
        sx={{
          height: size === "small" ? "1.5rem" : "3rem",
          width: size === "small" ? "1.5rem" : "3rem",
        }}
      />
    </Link>
  );
};
const LoadingLinkWrapper = (
  props: PropsWithChildren<{ loading: boolean; link: string }>
) =>
  props.loading ? (
    <>{props.children}</>
  ) : (
    <Link to={props.link} style={{ textDecoration: "none" }}>
      {props.children}
    </Link>
  );
const TabLink = (props: { loading: boolean; link: string; label: string }) => {
  const { loading, link, label } = props;
  const { pathname } = useLocation();

  return (
    <LoadingLinkWrapper loading={loading} link={link}>
      <Typography
        sx={{
          color: ({ palette }) =>
            (link === "/" && pathname === link) ||
            (link !== "/" && pathname.includes(link))
              ? palette.primary.main
              : "white",
        }}
        textAlign="center"
        size="sm"
      >
        {label}
      </Typography>
    </LoadingLinkWrapper>
  );
};

const Navbar = (props: NavbarProps) => {
  const { t } = useTranslation();
  const { konami, localPlayer } = useContext(RoomContext);
  const [openAS, setOpenAS] = useState<boolean>(false);
  const { pathname } = useLocation();

  const loading = localPlayer === undefined;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack direction="row" gap={2} alignItems="center">
        <ImgLink
          link={process.env.REACT_APP_HOME_URL ?? ""}
          image={require("assets/images/logo.png")}
          size="big"
        />
        <ImgLink
          link={"https://www.twitch.tv/aspig"}
          image={require("assets/images/twitch.png")}
          newTab
        />
        <ImgLink
          link={"https://www.youtube.com/@AsPigTv"}
          image={require("assets/images/youtube.png")}
          newTab
        />
        <ImgLink
          link={"https://twitter.com/AsPigTV"}
          image={require("assets/images/twitter.png")}
          newTab
        />
        <ImgLink
          link={"https://discord.com/invite/9nKbxnzRep"}
          image={require("assets/images/discord.png")}
          newTab
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        gap={2}
      >
        {/* <Link to={"/"} style={{ textDecoration: "none" }}>
          <Stack direction="row" alignItems="center">
            <CardMedia
              component="img"
              src={
                konami
                  ? require("assets/images/lumine.gif")
                  : require("assets/images/logo.png")
              }
              sx={{
                height: "3rem",
                width: "3rem",
              }}
            />
            <Typography
              textAlign="center"
              sx={{
                color: ({ palette }) =>
                  pathname === "/" ? palette.primary.main : "white",
              }}
            >
              {konami ? "La Reine des Zabysse" : t("L'Arène des Abysses")}
            </Typography>
            {konami && (
              <CardMedia
                component="img"
                src={require("assets/images/uwu.gif")}
                sx={{
                  height: "3rem",
                  width: "3rem",
                }}
              />
            )}
          </Stack>
        </Link> */}
        <TabLink loading={loading} link="/" label={t("Menu")} />
        <Divider orientation="vertical" flexItem />
        <Badge
          color="error"
          invisible={
            loading ||
            (localPlayer?.characters !== undefined &&
              localPlayer?.characters.length > 0)
          }
          badgeContent=""
        >
          <TabLink loading={loading} link="/box" label={t("Ma box")} />
        </Badge>
        <Divider orientation="vertical" flexItem />
        <TabLink
          loading={loading}
          link="/playersBox"
          label={t("Box des joueurs")}
        />
        <Divider orientation="vertical" flexItem />
        <TabLink loading={loading} link="/balancing" label={t("Équilibrage")} />
        <Divider orientation="vertical" flexItem />
        <Button
          onClick={() => setOpenAS(true)}
          sx={{ padding: 0 }}
          disabled={loading}
        >
          <Badge
            color="error"
            invisible={
              loading ||
              (localPlayer?.avatar !== undefined &&
                localPlayer.pseudo !== undefined)
            }
            badgeContent=" "
          >
            <Avatar character={localPlayer?.avatar} loading={loading} />
          </Badge>
        </Button>
        <AvatarSelector open={openAS} onClose={() => setOpenAS(false)} />
      </Stack>
    </Stack>
  );
};

export default Navbar;
