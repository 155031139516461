import {
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useState } from "react";
import GenshinDialog from "common/components/GenshinDialog";
import { useTranslation } from "react-i18next";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import { RoomContext } from "App/context";
import { GameModeDictionary } from "types/Room/type";

type ParamsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ParamsDialog = (props: ParamsDialogProps) => {
  const { open, onClose } = props;
  const { params } = useContext(RoomContext);
  const { t } = useTranslation();
  const [showRoomName, setShowRoomName] = useState<boolean>(false);

  return (
    <GenshinDialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        <Typography>{t("Paramètres du salon")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} paddingY={1}>
          <FormControl disabled size="small">
            <InputLabel>{t("Nom du salon")}</InputLabel>
            <OutlinedInput
              value={showRoomName ? params.name : "*******"}
              endAdornment={
                <InputAdornment position="end" sx={{ gap: "1rem" }}>
                  <IconButton
                    onClick={() => setShowRoomName(!showRoomName)}
                    edge="end"
                  >
                    {showRoomName ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <IconButton
                    onClick={async () =>
                      await navigator.clipboard.writeText(params.name)
                    }
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
              label={t("Nom du salon")}
            />
          </FormControl>
          <Typography size="sm">
            {t("Feuille d'équilibrage")} : {params.sheet.name}
          </Typography>
          <Typography size="sm">
            {t("Mode de jeu")} : {GameModeDictionary[params.gameMode]}
          </Typography>
        </Stack>
      </DialogContent>
    </GenshinDialog>
  );
};

export default ParamsDialog;
