import { Check, Close } from "@mui/icons-material";
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import { RoomContext } from "App/context";
import GenshinButton from "common/components/GenshinButton";
import SkewButton from "common/components/SkewButton";
import Typography from "common/components/Typography";
import BoxListWithContext from "pages/Draft/BoxList";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Draft, DraftCharacter } from "types/Draft/type";
import { Role, Room, RoomPlayer } from "types/Room/type";
import { JokerBansContext } from "./context";
import { whoAMI } from "common/utils";
import { useSocket } from "common/hooks/useSocket";

type JokerBansType = {
  badgeQuantity?: number;
  whoBan?: "player1" | "player2";
};

const JokerBans = (props: JokerBansType) => {
  const { badgeQuantity = 0, whoBan = "player1" } = props;
  const { t } = useTranslation();
  const { dispatch: dispatchRoom, player1, player2 } = useContext(RoomContext);
  const { dispatch, selectingBans, selectedBansPlayer1, selectedBansPlayer2 } =
    useContext(JokerBansContext);
  const socket = useSocket();

  const role = whoAMI(socket, player1, player2);

  const subscribeEvents = () => {
    socket.on("show-joker-bans", (room: Room, role?: Role) => {
      dispatchRoom({
        jokerBansPlayer1: room.jokerBansPlayer1 ?? undefined,
        jokerBansPlayer2: room.jokerBansPlayer2 ?? undefined,
      });
      dispatch({
        selectingBans: role ?? undefined,
        selectedBansPlayer1: role === "Player 1" ? [] : undefined,
        selectedBansPlayer2: role === "Player 2" ? [] : undefined,
      });
    });
    socket.on("selected-joker-ban", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") {
        dispatch({
          selectedBansPlayer1: chars,
        });
      } else {
        dispatch({
          selectedBansPlayer2: chars,
        });
      }
    });
  };

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("show-joker-bans");
      socket.removeListener("selected-joker-ban");
    };
  }, []);

  return (
    <Stack
      alignItems="center"
      direction="column"
      height="100%"
      gap={2}
      width={selectingBans !== undefined ? "100%" : undefined}
    >
      <Badge
        color={whoBan === "player1" ? "info" : "error"}
        badgeContent={Math.abs(badgeQuantity)}
        anchorOrigin={{
          vertical: "top",
          horizontal: whoBan === "player1" ? "left" : "right",
        }}
      >
        <GenshinButton
          disabled={
            role === "Spectator" ||
            player1 === undefined ||
            player2 === undefined ||
            (selectingBans !== undefined && selectingBans !== role)
          }
          variant="contained"
          onClick={() => {
            if (role !== "Spectator") {
              if (selectingBans !== undefined) {
                socket.emit("on-click-show-joker-bans");
              } else {
                socket.emit("on-click-show-joker-bans", role);
              }
            }
          }}
        >
          {t("Bans joker")}
        </GenshinButton>
      </Badge>
      {selectingBans !== undefined && (
        <Stack
          direction="column"
          alignItems="center"
          height="0px"
          width="100%"
          flexGrow={1}
          gap={1}
        >
          <BoxListWithContext />
          {selectingBans === role && (
            <SkewButton
              onClick={() =>
                socket.emit(
                  "send-joker-ban",
                  selectedBansPlayer1 ?? selectedBansPlayer2
                )
              }
            >
              {t("Bannir")}
            </SkewButton>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default JokerBans;
