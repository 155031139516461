import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { ReactNode, useEffect, useState } from "react";
import GenshinButton from "common/components/GenshinButton";
import { DividerHorizontal, DividerLeft, DividerRight } from "assets/icons";
import GenshinDialog from "common/components/GenshinDialog";
import { Link } from "react-router-dom";
import { CharacterPatchNote, PatchNote, Sheet } from "service/sheet/type";
import { useTranslation } from "react-i18next";
import { getPatchNotes } from "service/sheet";
import { formatDate } from "common/utils";
import { East, ExpandMore, NorthEast, SouthEast } from "@mui/icons-material";
import { API_URL } from "service/root/rootApi";

type HistoricalDialogProps = {
  open: boolean;
  onClose: () => void;
  sheet: Sheet | undefined;
};

const HistoricalDialog = (props: HistoricalDialogProps) => {
  const { open, sheet, onClose } = props;
  const { t } = useTranslation();
  const [name, setName] = useState<string>(sheet?.name ?? "");
  const [patchNotes, setPatchNotes] = useState<PatchNote[]>([]);
  const noData = patchNotes.length === 0;
  const fetch = async () => {
    if (sheet) {
      const data = await getPatchNotes(sheet.id);
      setPatchNotes(data.reverse());
    }
  };

  const renderConstelChanges = ({ newValue, oldValue }: CharacterPatchNote) => {
    let symbol: ReactNode;
    if (newValue > oldValue) symbol = <NorthEast color="error" />;
    else if (newValue < oldValue) symbol = <SouthEast color="success" />;
    else symbol = <East />;
    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Typography size="m">{oldValue}</Typography>
        {symbol}
        <Typography size="m">{newValue}</Typography>
      </Stack>
    );
  };

  useEffect(() => {
    fetch();
    if (open) {
      setName(sheet?.name ?? "");
      setPatchNotes([]);
    }
  }, [open]);

  return (
    <GenshinDialog
      open={props.open}
      maxWidth={"xl"}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        <Typography>{name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {patchNotes.map((patch) => (
            <Accordion key={patch.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                {formatDate(patch.date)}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  sx={{ backgroundColor: "unset" }}
                >
                  <Table sx={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          ".MuiTableCell-root:not(:first-of-type)": {
                            borderLeft: "1px solid white",
                          },
                          ".MuiTableCell-root:first-of-type": {
                            width: "20%",
                          },
                          ".MuiTableCell-root": {
                            borderBottom: "1px solid white",
                          },
                        }}
                      >
                        <TableCell>{t("Nom")}</TableCell>
                        <TableCell>{t("C0")}</TableCell>
                        <TableCell>{t("C1")}</TableCell>
                        <TableCell>{t("C2")}</TableCell>
                        <TableCell>{t("C3")}</TableCell>
                        <TableCell>{t("C4")}</TableCell>
                        <TableCell>{t("C5")}</TableCell>
                        <TableCell>{t("C6")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patch.characters.map((c, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            ".MuiTableCell-root:not(:first-of-type)": {
                              borderLeft: "1px solid white",
                            },
                            ".MuiTableCell-root": {
                              borderBottom: "1px solid white",
                            },
                          }}
                        >
                          <TableCell>
                            <Stack direction="row" gap={1} alignItems="center">
                              <CardMedia
                                component="img"
                                src={`${API_URL}assets/characters/${c.name}/thumb.png`}
                                sx={{
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                              {c.name}
                            </Stack>
                          </TableCell>
                          <TableCell>{renderConstelChanges(c.c0)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c1)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c2)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c3)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c4)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c5)}</TableCell>
                          <TableCell>{renderConstelChanges(c.c6)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
          {noData && (
            <Typography size="sm">
              {t("Aucun historique à afficher")}
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </GenshinDialog>
  );
};

export default HistoricalDialog;
