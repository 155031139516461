import { Badge } from "@mui/material";
import React from "react";
import GenshinButton from "common/components/GenshinButton";

type BanButtonProps = {
  badgeQuantity?: number;
  whoBan?: "player1" | "player2";
  label: string;
};

const BanButton = (props: BanButtonProps) => {
  const { badgeQuantity = 0, whoBan, label } = props;

  return (
    <Badge
      color={whoBan === "player1" ? "info" : "error"}
      badgeContent={Math.abs(badgeQuantity)}
      anchorOrigin={{
        vertical: "top",
        horizontal: whoBan === "player1" ? "left" : "right",
      }}
    >
      <GenshinButton disabled variant="contained">
        {label}
      </GenshinButton>
    </Badge>
  );
};

export default BanButton;
