import {
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight } from "assets/icons";
import { WithGrow } from "common/components/WithGrow";
import { Sheet } from "service/sheet/type";
import { Cancel, CheckCircle, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getSheets, createSheet } from "service/sheet";
import { RoomContext } from "App/context";
import { formatDate, isUserAdmin, isUserOrganisator } from "common/utils";
import {
  NotificationContext,
  useErrorNotification,
} from "common/components/Notification";
import HistoricalDialog from "./HistoricalDialog";

type BalancingProps = {};

const Balancing = (props: BalancingProps) => {
  const { t } = useTranslation();
  const { localPlayer } = useContext(RoomContext);
  const isAdmin = isUserAdmin(localPlayer);
  const isOrga = isUserOrganisator(localPlayer);
  const { dispatchNotification } = useContext(NotificationContext);
  const dispatchError = useErrorNotification();
  const [list, setList] = useState<Sheet[] | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [currentSheetHistoric, setCurrentSheetHistoric] = useState<
    Sheet | undefined
  >(undefined);
  const navigate = useNavigate();

  const handleCreateSheet = async () => {
    try {
      if (name !== "") {
        setLoadingCreate(true);
        await createSheet(name);
        fetch();
        dispatchNotification({
          message: t("La feuille a été créée avec succès"),
          severity: "success",
        });
      }
    } catch (error) {
      dispatchError(error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const fetch = async () => {
    setList(await getSheets());
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <WithGrow
      direction="column"
      sx={{
        gap: 2,
      }}
      fixedSx={{
        width: "100%",
        alignItems: "center",
      }}
      fixed={
        <React.Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              svg: { width: "15rem" },
            }}
          >
            <DividerRight />
            <Typography>{t("Équilibrage")}</Typography>
            <DividerLeft />
          </Stack>
        </React.Fragment>
      }
    >
      <WithGrow
        direction="column"
        sx={{
          gap: 2,
        }}
        fixed={
          <Stack justifyContent="space-between" direction="row" py="0.5rem">
            <Stack>
              <IconButton
                onClick={() => {
                  setList(undefined);
                  fetch();
                }}
              >
                <Refresh />
              </IconButton>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              visibility={isAdmin || isOrga ? "visible" : "hidden"}
            >
              <TextField
                onChange={(evt) => setName(evt.target.value)}
                label={t("Nom de la feuille")}
                value={name}
                required
              />
              <GenshinButton
                loading={loadingCreate}
                onClick={handleCreateSheet}
              >
                {t("Créer")}
              </GenshinButton>
            </Stack>
          </Stack>
        }
      >
        <Stack height={0} minHeight="100%" overflow="auto">
          <TableContainer component={Paper} sx={{ backgroundColor: "unset" }}>
            <Table sx={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow
                  sx={{
                    "*:not(:first-of-type)": {
                      borderLeft: "1px solid rgba(81, 81, 81)",
                    },
                    ".MuiTableCell-root": { borderBottomWidth: "3px" },
                  }}
                >
                  <TableCell>{t("Nom")}</TableCell>
                  <TableCell>{t("Créateur")}</TableCell>
                  <TableCell>{t("Date de création")}</TableCell>
                  <TableCell>{t("Dernière mise à jour")}</TableCell>
                  <TableCell>{t("Active")}</TableCell>
                  <TableCell>{t("Historique")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((sheet, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      ":hover": {
                        animation: "opacity 0.1s linear",
                        opacity: 0.6,
                        cursor: "pointer",
                      },
                      bgcolor: index % 2 === 0 ? "#14191F" : "#353535",
                      "*:not(:first-of-type)": {
                        borderLeft: "1px solid rgba(81, 81, 81)",
                      },
                      "@keyframes opacity": {
                        from: {
                          opacity: 1,
                        },
                        to: {
                          opacity: 0.6,
                        },
                      },
                    }}
                    onClick={() => {
                      navigate(`/balancing/${sheet.id}`);
                    }}
                  >
                    <TableCell>{sheet.name}</TableCell>
                    <TableCell>{sheet.owner}</TableCell>
                    <TableCell>{formatDate(sheet.createdAt)}</TableCell>
                    <TableCell>{formatDate(sheet.updatedAt)}</TableCell>
                    <TableCell>
                      {sheet.available ? (
                        <CheckCircle color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <GenshinButton
                        onClick={(e) => {
                          setCurrentSheetHistoric(sheet);
                          e.stopPropagation();
                        }}
                      >
                        {t("Voir l'historique")}
                      </GenshinButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {list == undefined && <LinearProgress />}
          </TableContainer>
        </Stack>
      </WithGrow>
      <HistoricalDialog
        open={currentSheetHistoric !== undefined}
        sheet={currentSheetHistoric}
        onClose={() => setCurrentSheetHistoric(undefined)}
      />
    </WithGrow>
  );
};

export default Balancing;
