import {
  CardMedia,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CharacterSheet } from "service/sheet/type";
import { Add, Remove } from "@mui/icons-material";
import { RoomContext } from "App/context";
import { isUserAdmin } from "common/utils";
import { ELEMENTS } from "pages/Draft/BoxList/FilteringBar";
import { API_URL } from "service/root/rootApi";

type SheetTableRowProps = {
  canEdit: boolean;
  characterSheet: CharacterSheet;
  onClickPlusMinus: (
    char: CharacterSheet,
    operator: number,
    constellation?: Exclude<keyof CharacterSheet, "character">
  ) => void;
  onChangeConstellation: (
    char: CharacterSheet,
    newValue: number,
    constellation: Exclude<keyof CharacterSheet, "character">
  ) => void;
};

const ConstellationCell = (
  props: SheetTableRowProps & {
    constellation: Exclude<keyof CharacterSheet, "character">;
  }
) => {
  const {
    canEdit,
    characterSheet,
    onClickPlusMinus,
    constellation,
    onChangeConstellation,
  } = props;
  const [isFocusing, setIsFocusing] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    setLocalValue(characterSheet[constellation]);
  }, [characterSheet[constellation]]);

  return (
    <ClickAwayListener
      mouseEvent={canEdit ? "onMouseDown" : false}
      onClickAway={() => {
        setIsFocusing(false);
        if (isFocusing && canEdit) {
          onChangeConstellation(characterSheet, localValue ?? 0, constellation);
          if (localValue === undefined) setLocalValue(0);
        }
      }}
    >
      <TableCell
        onClick={() => setIsFocusing(true)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        sx={{
          padding: 0,
          fontSize: "1.25rem",
          cursor: canEdit ? "pointer" : "unset",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          padding={"0.5rem"}
          justifyContent="space-between"
          border={`1px solid ${isHovering && !isFocusing ? "white" : "unset"}`}
          borderRadius="1rem"
          gap={1}
        >
          {isFocusing && canEdit ? (
            <TextField
              type="number"
              inputRef={(input) => input && input.focus()}
              value={localValue ?? ""}
              onChange={(evt) => {
                if (evt.target.value === "") setLocalValue(undefined);
                const value = parseInt(evt.target.value);
                if (value >= 0) setLocalValue(value);
              }}
              sx={{
                padding: 0,
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  { WebkitAppearance: "none" },
              }}
            />
          ) : (
            characterSheet[constellation]
          )}
          {isFocusing && canEdit && (
            <Stack direction="column" gap={0.5}>
              <IconButton
                sx={{
                  ":hover": {
                    bgcolor: ({ palette }) => palette.success.dark,
                    opacity: "0.6",
                  },
                  svg: {
                    height: "20px",
                    width: "20px",
                  },
                  bgcolor: ({ palette }) => palette.success.dark,
                  padding: "0",
                  borderRadius: "0.5rem",
                }}
                onClick={() =>
                  onClickPlusMinus(characterSheet, 1, constellation)
                }
              >
                <Add />
              </IconButton>
              <IconButton
                sx={{
                  ":hover": {
                    bgcolor: ({ palette }) => palette.error.main,
                    opacity: "0.6",
                  },
                  svg: {
                    height: "20px",
                    width: "20px",
                  },
                  bgcolor: ({ palette }) => palette.error.main,
                  padding: "0 0.5rem",
                  borderRadius: "0.5rem",
                }}
                onClick={() =>
                  onClickPlusMinus(characterSheet, -1, constellation)
                }
              >
                <Remove />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </TableCell>
    </ClickAwayListener>
  );
};

const SheetTableRow = (props: SheetTableRowProps) => {
  const { canEdit, characterSheet, onClickPlusMinus, onChangeConstellation } =
    props;
  const [isFocusing, setIsFocusing] = useState<boolean>(false);

  return (
    <ClickAwayListener onClickAway={() => setIsFocusing(false)}>
      <TableRow
        onClick={() => setIsFocusing(true)}
        sx={{
          bgcolor: ({ palette }) => palette.background.highlight,
          ">:not(:first-of-type)": {
            borderLeft: "1px solid rgba(81, 81, 81, 1)",
          },
        }}
      >
        <TableCell sx={{ padding: "0.5rem" }}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {Array.isArray(characterSheet.character.element)
                ? ""
                : ELEMENTS[characterSheet.character.element]}
              <CardMedia
                component="img"
                src={`${API_URL}assets/characters/${characterSheet.character.name}/thumb.png`}
                sx={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
              {characterSheet.character.name}
            </Stack>
            {isFocusing && canEdit && (
              <Stack direction="row" gap={2}>
                <IconButton
                  sx={{
                    ":hover": {
                      bgcolor: ({ palette }) => palette.error.main,
                      opacity: "0.6",
                    },
                    bgcolor: ({ palette }) => palette.error.main,
                  }}
                  onClick={() => onClickPlusMinus(characterSheet, -1)}
                >
                  <Remove />
                </IconButton>
                <IconButton
                  sx={{
                    ":hover": {
                      bgcolor: ({ palette }) => palette.success.dark,
                      opacity: "0.6",
                    },
                    bgcolor: ({ palette }) => palette.success.dark,
                  }}
                  onClick={() => onClickPlusMinus(characterSheet, 1)}
                >
                  <Add />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c0"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c1"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c2"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c3"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c4"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c5"
        />
        <ConstellationCell
          canEdit={canEdit}
          characterSheet={characterSheet}
          onClickPlusMinus={onClickPlusMinus}
          onChangeConstellation={onChangeConstellation}
          constellation="c6"
        />
      </TableRow>
    </ClickAwayListener>
  );
};

export default SheetTableRow;
