import { debounce } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

const useLocalDebounce = (callback: () => void) => {
  const ref = useRef(() => {});

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const fn = () => {
      ref.current?.();
    };

    return debounce(fn, 200);
  }, []);

  return debouncedCallback;
};

export const useDebouncedState = <T,>(
  value: T,
  callback: (value: T) => void
): [value: T, setValue: (newValue: T) => void] => {
  const [innerValue, setInnerValue] = useState<T>(value);
  const debounced = useLocalDebounce(() => callback(innerValue));

  useEffect(() => {
    if (value !== innerValue) setInnerValue(value);
  }, [value]);

  const handleSetValue = (newValue: T) => {
    setInnerValue(newValue);
    debounced();
  };
  return [innerValue, handleSetValue];
};
