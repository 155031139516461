import {
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight } from "assets/icons";
import { WithGrow } from "common/components/WithGrow";
import { useNavigate, useParams } from "react-router-dom";
import { CharacterSheet, Sheet } from "service/sheet/type";
import { getSheet, updateSheet } from "service/sheet";
import FilteringBar, { ELEMENTS } from "pages/Draft/BoxList/FilteringBar";
import SheetTableRow from "./SheetTableRow";
import { CloudDownload, CloudUpload, Delete } from "@mui/icons-material";
import {
  NotificationContext,
  useErrorNotification,
} from "common/components/Notification";
import { RoomContext } from "App/context";
import { Character } from "types/Character/type";
import { FilterContext, FilterProvider } from "pages/Draft/BoxList/context";
import ConfirmDelete from "./ConfirmDelete";
import { isUserAdmin, isUserOrganisator } from "common/utils";
import ConfirmLeave from "./ConfirmLeave";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type SheetTableProps = {};

const SheetTable = (props: SheetTableProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { charactersList, localPlayer } = useContext(RoomContext);
  const isAdmin = isUserAdmin(localPlayer);
  const isOrga = isUserOrganisator(localPlayer);
  const { element, rarity, search, linesWithValues } =
    useContext(FilterContext);
  const { dispatchNotification } = useContext(NotificationContext);
  const dispatchError = useErrorNotification();
  const navigate = useNavigate();
  const [sheet, setSheet] = useState<Sheet | undefined>(undefined);
  const [characters, setCharacters] = useState<CharacterSheet[]>([]);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openLeave, setOpenLeave] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const onClickPlusMinus = (
    char: CharacterSheet,
    operator: number,
    constellation?: Exclude<keyof CharacterSheet, "character">
  ) => {
    const index = characters.findIndex(
      (c) => c.character.name === char.character.name
    );
    const applyValue = (value: number, operator: number) => {
      const newValue = value + operator;
      if (newValue < 0) return 0;
      else return newValue;
    };
    if (constellation === undefined) {
      characters[index].c0 = applyValue(characters[index].c0, operator);
      characters[index].c1 = applyValue(characters[index].c1, operator);
      characters[index].c2 = applyValue(characters[index].c2, operator);
      characters[index].c3 = applyValue(characters[index].c3, operator);
      characters[index].c4 = applyValue(characters[index].c4, operator);
      characters[index].c5 = applyValue(characters[index].c5, operator);
      characters[index].c6 = applyValue(characters[index].c6, operator);
    } else {
      characters[index][constellation] = applyValue(
        characters[index][constellation],
        operator
      );
    }
    setCharacters([...characters]);
  };
  const onChangeConstellation = (
    char: CharacterSheet,
    newValue: number,
    constellation: Exclude<keyof CharacterSheet, "character">
  ) => {
    const index = characters.findIndex(
      (c) => c.character.name === char.character.name
    );
    characters[index][constellation] = newValue;
    setCharacters([...characters]);
  };

  const handleUploadSheet = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0] && sheet !== undefined) {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        try {
          const csv = (e.target?.result as string).split("\n");
          const data: CharacterSheet[] = [];
          for (const c of csv) {
            const charInfo = c.split(";");
            const cList = charactersList.find((c) => c.name === charInfo[0]);
            if (cList) {
              data.push({
                character: cList,
                c0: parseInt(charInfo[1]),
                c1: parseInt(charInfo[2]),
                c2: parseInt(charInfo[3]),
                c3: parseInt(charInfo[4]),
                c4: parseInt(charInfo[5]),
                c5: parseInt(charInfo[6]),
                c6: parseInt(charInfo[7]),
              });
            }
          }
          setCharacters(data);
          dispatchNotification({
            message: t("La feuille a été importée avec succès"),
            severity: "success",
          });
        } catch (error) {
          dispatchError(error);
        }
      };
    }
  };

  const handleSaveSheet = async () => {
    try {
      if (sheet !== undefined) {
        setLoadingSave(true);
        await updateSheet(id, {
          ...sheet,
          characters: characters.filter(
            (c) =>
              c.c0 !== 0 ||
              c.c1 !== 0 ||
              c.c2 !== 0 ||
              c.c3 !== 0 ||
              c.c4 !== 0 ||
              c.c5 !== 0 ||
              c.c6 !== 0
          ),
        });
        fetch();
        dispatchNotification({
          message: t("La feuille a été sauvegardée avec succès"),
          severity: "success",
        });
      }
    } catch (error) {
      dispatchError(error);
    } finally {
      setLoadingSave(false);
    }
  };

  const fetch = async () => {
    try {
      const sheet: Sheet = await getSheet(id);
      setSheet(sheet);
      setCanEdit(isAdmin || (isOrga && sheet.owner === localPlayer?.pseudo));

      const getCharacterSheet = (char: Character): CharacterSheet => {
        const charSheet = sheet.characters.find(
          (c) => c.character.name === char.name
        );
        if (charSheet !== undefined) return { ...charSheet };
        else
          return {
            character: char,
            c0: 0,
            c1: 0,
            c2: 0,
            c3: 0,
            c4: 0,
            c5: 0,
            c6: 0,
          };
      };
      let chars: CharacterSheet[] = [];
      for (const element of Object.keys(ELEMENTS)) {
        let fourStars: CharacterSheet[] = [];
        let fiveStars: CharacterSheet[] = [];
        for (const c of charactersList) {
          if (c.element === element) {
            if (c.rarity === 4) fourStars.push(getCharacterSheet(c));
            else if (c.rarity === 5) fiveStars.push(getCharacterSheet(c));
          }
        }
        chars.push(...fiveStars, ...fourStars);
      }
      setCharacters(chars);
    } catch (error) {
      dispatchError(error);
    }
  };

  const convertDataToCSV = () => {
    let res = "";
    const chars = characters.filter(
      (c) =>
        c.c0 !== 0 ||
        c.c1 !== 0 ||
        c.c2 !== 0 ||
        c.c3 !== 0 ||
        c.c4 !== 0 ||
        c.c5 !== 0 ||
        c.c6 !== 0
    );
    for (const c of chars) {
      const data = [c.character.name, c.c0, c.c1, c.c2, c.c3, c.c4, c.c5, c.c6];
      res += data.join(";") + "\n";
    }
    return res;
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <WithGrow
      direction="column"
      sx={{
        gap: 2,
      }}
      fixedSx={{
        width: "100%",
        alignItems: "center",
      }}
      fixed={
        <React.Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              svg: { width: "15rem" },
            }}
          >
            <DividerRight />
            <Typography>{t("Équilibrage")}</Typography>
            <DividerLeft />
          </Stack>
        </React.Fragment>
      }
    >
      <ConfirmLeave open={openLeave} setOpen={setOpenLeave} />
      <ConfirmDelete open={openDelete} setOpen={setOpenDelete} />
      <WithGrow
        direction="column"
        sx={{
          gap: 2,
        }}
        fixed={
          <Stack direction="column" gap={1}>
            <Stack justifyContent="space-between" direction="row" py="0.5rem">
              <Stack>
                <GenshinButton
                  onClick={() => {
                    if (
                      JSON.stringify(sheet?.characters) !==
                      JSON.stringify(
                        characters.filter(
                          (c) =>
                            c.c0 !== 0 ||
                            c.c1 !== 0 ||
                            c.c2 !== 0 ||
                            c.c3 !== 0 ||
                            c.c4 !== 0 ||
                            c.c5 !== 0 ||
                            c.c6 !== 0
                        )
                      )
                    ) {
                      setOpenLeave(true);
                    } else {
                      navigate("/balancing");
                    }
                  }}
                >{`< ${t("Liste")}`}</GenshinButton>
              </Stack>
              <Stack direction="row" gap={2}>
                <FormControlLabel
                  sx={{ gap: "0.5rem" }}
                  disabled={!canEdit}
                  control={
                    <Switch
                      checked={sheet?.available ?? false}
                      onChange={(evt) =>
                        sheet
                          ? setSheet({
                              ...sheet,
                              available: evt.target.checked,
                            })
                          : {}
                      }
                    />
                  }
                  label={t("Active")}
                />
                <TextField
                  onChange={(evt) =>
                    sheet ? setSheet({ ...sheet, name: evt.target.value }) : {}
                  }
                  disabled={!canEdit}
                  label={t("Nom de la feuille")}
                  value={sheet ? sheet.name : ""}
                  required
                />
                {canEdit && (
                  <GenshinButton
                    loading={loadingSave}
                    onClick={handleSaveSheet}
                  >
                    {t("Sauvegarder")}
                  </GenshinButton>
                )}
              </Stack>
            </Stack>
            <Stack direction="row">
              {canEdit && (
                <Tooltip title={t("Supprimer la feuille")} arrow>
                  <IconButton
                    color="error"
                    onClick={() => setOpenDelete(true)}
                    sx={{ px: 1.5 }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
              <FilteringBar mode="balancing" />
              {canEdit && (
                <Stack
                  sx={{ ".MuiButtonBase-root": { px: 1.5 } }}
                  direction="row"
                >
                  <Tooltip title={t("Importer")} arrow>
                    <IconButton component="label">
                      <CloudUpload />
                      <VisuallyHiddenInput
                        type="file"
                        accept=".csv,application/csv"
                        onChange={(event) => handleUploadSheet(event)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Télécharger")} arrow>
                    <IconButton
                      href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                        convertDataToCSV()
                      )}`}
                      download={`${sheet?.name ?? "sheet"}.csv`}
                    >
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </Stack>
        }
      >
        <Stack height={0} minHeight="100%" overflow="auto">
          <TableContainer component={Paper} sx={{ backgroundColor: "unset" }}>
            <Table sx={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    zIndex: 1,
                    top: 0,
                    bgcolor: ({ palette }) => palette.background.default,
                    ".MuiTableCell-root:first-of-type": {
                      width: "30%",
                    },
                    "*:not(:first-of-type)": {
                      borderLeft: "1px solid rgba(81, 81, 81)",
                    },
                    ".MuiTableCell-root": { borderBottomWidth: "3px" },
                  }}
                >
                  <TableCell>{t("Nom")}</TableCell>
                  <TableCell>{t("C0")}</TableCell>
                  <TableCell>{t("C1")}</TableCell>
                  <TableCell>{t("C2")}</TableCell>
                  <TableCell>{t("C3")}</TableCell>
                  <TableCell>{t("C4")}</TableCell>
                  <TableCell>{t("C5")}</TableCell>
                  <TableCell>{t("C6")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {characters
                  .filter((charSheet) => {
                    const ch = charSheet.character;
                    if (search !== "") {
                      if (
                        linesWithValues &&
                        charSheet.c0 === 0 &&
                        charSheet.c1 === 0 &&
                        charSheet.c2 === 0 &&
                        charSheet.c3 === 0 &&
                        charSheet.c4 === 0 &&
                        charSheet.c5 === 0 &&
                        charSheet.c6 === 0
                      )
                        return false;
                      return ch.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    }
                    if (
                      (element !== null
                        ? Array.isArray(ch.element)
                          ? ch.element.includes(element)
                          : ch.element === element
                        : true) &&
                      (rarity !== null ? ch.rarity === rarity : true) &&
                      (linesWithValues
                        ? charSheet.c0 !== 0 ||
                          charSheet.c1 !== 0 ||
                          charSheet.c2 !== 0 ||
                          charSheet.c3 !== 0 ||
                          charSheet.c4 !== 0 ||
                          charSheet.c5 !== 0 ||
                          charSheet.c6 !== 0
                        : true)
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((character, index) => (
                    <SheetTableRow
                      key={index}
                      canEdit={canEdit}
                      characterSheet={character}
                      onClickPlusMinus={onClickPlusMinus}
                      onChangeConstellation={onChangeConstellation}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {sheet == undefined && <LinearProgress />}
        </Stack>
      </WithGrow>
    </WithGrow>
  );
};

const Wrapper = () => (
  <FilterProvider>
    <SheetTable />
  </FilterProvider>
);

export default Wrapper;
