import { Grid, GridProps } from "@mui/material";
import React from "react";

type WithGrowType = {
  fixed: React.ReactNode;
  direction?: GridProps["direction"];
  className?: string;
  sx?: GridProps["sx"];
  fixedSx?: GridProps["sx"];
  containerSx?: GridProps["sx"];
  height?: string;
};
export const WithGrow = (props: React.PropsWithChildren<WithGrowType>) => {
  const {
    fixed,
    children,
    fixedSx = {},
    containerSx = {},
    height = "100%",
    ...other
  } = props;
  return (
    <Grid container height={height} wrap="nowrap" {...other}>
      <Grid item sx={fixedSx}>
        {fixed}
      </Grid>
      <Grid
        item
        height="100%"
        flexGrow={1}
        sx={[
          { overflow: "hidden" },
          ...(Array.isArray(containerSx) ? containerSx : [containerSx]),
        ]}
      >
        {children}
      </Grid>
    </Grid>
  );
};
