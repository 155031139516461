import API from "service/root/rootApi";
import { MatchDataOut, DraftParams } from "./type";
import { CharacterBox } from "service/player/type";
import { Character } from "types/Character/type";

export const getDraftParams = async (
  boxPlayer1: CharacterBox[],
  boxPlayer2: CharacterBox[],
  sheetId: number
) => {
  const response = await API.post<DraftParams>("/draft-params", {
    boxPlayer1,
    boxPlayer2,
    sheetId,
  });
  return response.data;
};

export const getScore = async (characters: CharacterBox[], sheetId: number) => {
  const response = await API.post<number>("/score", {
    sheetId,
    characters,
  });
  return (response.data as any).score;
};

export const getCharacters = async () => {
  const response = await API.get<Character[]>("/characters");
  return response.data;
};

export const postMatchData = async (data: MatchDataOut) => {
  const response = await API.post("/match", data);
  return response.data;
};
