import API from "service/root/rootApi";
import { CharacterBox, Player } from "./type";

export const getPlayer = async () => {
  const response = await API.get<Player>("/player");
  return response.data;
};

export const getPlayers = async () => {
  const response = await API.get<Player[]>("/players");
  return response.data;
};

export const getToken = async () => {
  const response = await API.get<Player>("/token");
  return response.data;
};

export const updatePlayer = async (player: Player) => {
  const response = await API.post("/player", player);
  return response.data;
};

export const createBox = async (characters: CharacterBox[]) => {
  const response = await API.post("/box", characters);
  return response.data;
};

export const updateBox = async (characters: CharacterBox[]) => {
  const response = await API.put(`/box`, characters);
  return response.data;
};

export const updatePreferences = async (
  sheetId: number,
  characters: CharacterBox[]
) => {
  const response = await API.put(`/box/${sheetId}/preferences`, characters);
  return response.data;
};
