import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedState } from "common/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { DividerLeft, DividerRight, Icon4Star, Icon5Star } from "assets/icons";
import { useErrorNotification } from "common/components/Notification";
import { RoomContext } from "App/context";
import { WithGrow } from "common/components/WithGrow";
import Paper from "common/components/Paper";
import PlayerCard from "./PlayerCard";
import { getPlayers } from "service/player";
import { CharacterBox, Player } from "service/player/type";
import ComparePlayerCard from "./ComparePlayerCard";
import RowCharacter from "./RowCharacter";
import { DraftParams } from "service/draft/type";
import { getDraftParams, getScore } from "service/draft";
import BanButton from "./BanButton";
import { Backspace, ExpandMore } from "@mui/icons-material";
import { GameMode, GameModeDictionary } from "types/Room/type";
import { applyBoxPreferences } from "common/utils";
import { Character } from "types/Character/type";

type PlayersBoxProps = {};

const PlayersBox = (props: PlayersBoxProps) => {
  const { localPlayer, charactersList, availableSheets } =
    useContext(RoomContext);
  const dispatchError = useErrorNotification();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    search,
    (value) => setSearch(value)
  );
  const [playersList, setPlayersList] = useState<Player[]>([]);
  const [player1, setPlayer1] = useState<Player | undefined>(localPlayer);
  const [scoreP1, setScoreP1] = useState<number | undefined>();
  const [player2, setPlayer2] = useState<Player | undefined>();
  const [scoreP2, setScoreP2] = useState<number | undefined>();
  const [player3, setPlayer3] = useState<Player | undefined>();
  const [scoreP3, setScoreP3] = useState<number | undefined>();
  const [player4, setPlayer4] = useState<Player | undefined>();
  const [scoreP4, setScoreP4] = useState<number | undefined>();
  const [scoreP1AndP3, setScoreP1AndP3] = useState<number | undefined>();
  const [scoreP2AndP4, setScoreP2AndP4] = useState<number | undefined>();
  const [draftParams, setDraftParams] = useState<DraftParams | undefined>();
  const [selectedSheet, setSelectedSheet] = useState<number>(
    availableSheets[0].id
  );
  const [selectedGameMode, setSelectedGameMode] = useState<GameMode>(
    GameMode._1vs1
  );
  const is1V1 = selectedGameMode === GameMode._1vs1;
  const is2V2 = selectedGameMode === GameMode._2vs2;

  const updateScore = async (
    player: Player,
    setScore: React.Dispatch<React.SetStateAction<number | undefined>>,
    sheetId: number
  ) => {
    const preferences = player.preferences?.find(
      (p) => p.sheetId === selectedSheet
    )?.characters;
    setScore(
      await getScore(
        applyBoxPreferences(player?.characters ?? [], preferences),
        sheetId
      )
    );
  };

  const mergeBoxes = (box1: CharacterBox[], box2: CharacterBox[]) => {
    let res: CharacterBox[] = [];
    box1.map(({ name, constellation }) => {
      const charBox2 = box2.find((c) => c.name === name);
      const constelBox2 = charBox2?.constellation ?? -1;
      res.push({
        name,
        constellation:
          constellation > constelBox2 ? constellation : constelBox2,
      });
    });
    box2.map(({ name, constellation }) => {
      if (!res.some((c) => c.name === name)) {
        res.push({ name, constellation: constellation });
      }
    });
    return res;
  };

  const getConstellation = (
    player: Player | undefined,
    character: Character
  ) => {
    const preferences = player?.preferences?.find(
      (p) => p.sheetId === selectedSheet
    )?.characters;
    if (preferences?.some((c) => c.name === character.name)) return undefined;
    else
      return player?.characters?.find((c) => c.name === character.name)
        ?.constellation;
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setPlayersList(await getPlayers());
      } catch (e) {
        dispatchError(e);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (player1 !== undefined && selectedSheet !== undefined) {
      updateScore(player1, setScoreP1, selectedSheet);
    } else setScoreP1(undefined);
  }, [player1, selectedSheet]);
  useEffect(() => {
    if (player2 !== undefined && selectedSheet !== undefined) {
      updateScore(player2, setScoreP2, selectedSheet);
    } else setScoreP2(undefined);
  }, [player2, selectedSheet]);
  useEffect(() => {
    if (player3 !== undefined && selectedSheet !== undefined) {
      updateScore(player3, setScoreP3, selectedSheet);
    } else setScoreP3(undefined);
  }, [player3, selectedSheet]);
  useEffect(() => {
    if (player4 !== undefined && selectedSheet !== undefined) {
      updateScore(player4, setScoreP4, selectedSheet);
    } else setScoreP4(undefined);
  }, [player4, selectedSheet]);

  useEffect(() => {
    if (
      is1V1 &&
      player1 !== undefined &&
      player2 !== undefined &&
      selectedSheet !== undefined
    ) {
      const checkDraftParams = async () => {
        const boxPlayer1 = player1.characters;
        const preferencesBoxPlayer1 = player1.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        const boxPlayer2 = player2.characters;
        const preferencesBoxPlayer2 = player2.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        if (boxPlayer1 !== undefined && boxPlayer2 !== undefined) {
          setDraftParams(
            await getDraftParams(
              applyBoxPreferences(boxPlayer1, preferencesBoxPlayer1),
              applyBoxPreferences(boxPlayer2, preferencesBoxPlayer2),
              selectedSheet
            )
          );
        }
      };
      checkDraftParams();
    } else if (
      is2V2 &&
      player1 !== undefined &&
      player2 !== undefined &&
      player3 !== undefined &&
      player4 !== undefined &&
      selectedSheet !== undefined
    ) {
      const checkDraftParams = async () => {
        const boxPlayer1 = player1.characters;
        const preferencesBoxPlayer1 = player1.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        const boxPlayer2 = player2.characters;
        const preferencesBoxPlayer2 = player2.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        const boxPlayer3 = player3.characters;
        const preferencesBoxPlayer3 = player3.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        const boxPlayer4 = player4.characters;
        const preferencesBoxPlayer4 = player4.preferences?.find(
          (p) => p.sheetId === selectedSheet
        )?.characters;
        if (
          boxPlayer1 !== undefined &&
          boxPlayer2 !== undefined &&
          boxPlayer3 !== undefined &&
          boxPlayer4 !== undefined
        ) {
          const p1Andp3 = mergeBoxes(
            applyBoxPreferences(boxPlayer1, preferencesBoxPlayer1),
            applyBoxPreferences(boxPlayer3, preferencesBoxPlayer3)
          );
          const p2Andp4 = mergeBoxes(
            applyBoxPreferences(boxPlayer2, preferencesBoxPlayer2),
            applyBoxPreferences(boxPlayer4, preferencesBoxPlayer4)
          );
          setScoreP1AndP3(await getScore(p1Andp3, selectedSheet));
          setScoreP2AndP4(await getScore(p2Andp4, selectedSheet));
          setDraftParams(await getDraftParams(p1Andp3, p2Andp4, selectedSheet));
        }
      };
      checkDraftParams();
    } else {
      setScoreP1AndP3(undefined);
      setScoreP2AndP4(undefined);
      setDraftParams(undefined);
    }
  }, [scoreP1, scoreP2, scoreP3, scoreP4]);

  return (
    <WithGrow
      direction="column"
      sx={{
        gap: 2,
      }}
      fixedSx={{
        width: "100%",
        alignItems: "center",
      }}
      fixed={
        <React.Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              svg: { width: "15rem" },
            }}
          >
            <DividerRight />
            <Typography>{t("Box des joueurs")}</Typography>
            <DividerLeft />
          </Stack>
          <Stack
            width="100%"
            direction="row"
            justifyContent="start"
            gap={1}
            pt={1}
          >
            <FormControl size="small">
              <InputLabel>{t("Chercher")}</InputLabel>
              <OutlinedInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <Backspace />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("Chercher")}
              />
            </FormControl>
            <FormControl sx={{ width: "15rem" }}>
              <InputLabel>{t("Feuille d'équilibrage")}</InputLabel>
              <Select
                label={t("Feuille d'équilibrage")}
                value={selectedSheet ?? ""}
                onChange={(evt) => {
                  setSelectedSheet(evt.target.value as number);
                  setScoreP1(undefined);
                  setScoreP2(undefined);
                  setScoreP1AndP3(undefined);
                  setScoreP2AndP4(undefined);
                  setDraftParams(undefined);
                }}
              >
                {availableSheets.map((s) => (
                  <MenuItem value={s.id} key={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: "10rem" }}>
              <InputLabel>{t("Mode de jeu")}</InputLabel>
              <Select
                label={t("Mode de jeu")}
                value={selectedGameMode}
                onChange={(evt) => {
                  setSelectedGameMode(evt.target.value as GameMode);
                  setPlayer3(undefined);
                  setPlayer4(undefined);
                }}
              >
                {Object.values(GameMode).map((n, i) => (
                  <MenuItem value={n} key={i}>
                    {GameModeDictionary[n]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </React.Fragment>
      }
    >
      <Stack direction="row" height="100%" gap={2}>
        <Stack height={0} minHeight="100%" flex={1}>
          <Paper
            sx={{
              backgroundColor: "unset",
              width: "100%",
              display: "grid",
              gridTemplateColumns:
                playersList.length > 0
                  ? "repeat(auto-fill, minmax(130px, 1fr))"
                  : "",
              gap: (theme) => theme.spacing(2),
              overflow: "auto",
              height: "100%",
            }}
          >
            {playersList.length === 0 ? (
              <CircularProgress
                sx={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              playersList
                .filter((player) =>
                  player.pseudo?.toLowerCase().includes(search.toLowerCase())
                )
                .sort((playerA, playerB) =>
                  (playerA.pseudo ?? "").localeCompare(playerB.pseudo ?? "")
                )
                .map((player, index) => (
                  <PlayerCard
                    key={index}
                    player={player}
                    selected={
                      player1?.id === player.id ||
                      player2?.id === player.id ||
                      player3?.id === player.id ||
                      player4?.id === player.id
                    }
                    onClick={() => {
                      if (player1?.id === player.id) setPlayer1(undefined);
                      else if (player2?.id === player.id) setPlayer2(undefined);
                      else if (player3?.id === player.id) setPlayer3(undefined);
                      else if (player4?.id === player.id) setPlayer3(undefined);
                      else {
                        if (player1 === undefined) setPlayer1(player);
                        else if (is2V2 && player3 === undefined)
                          setPlayer3(player);
                        else if (is1V1 || player2 === undefined)
                          setPlayer2(player);
                        else if (is2V2 || player4 === undefined)
                          setPlayer4(player);
                      }
                    }}
                  />
                ))
            )}
          </Paper>
        </Stack>
        <Stack flex={1} height={0} minHeight="100%">
          <Paper sx={{ height: "100%" }}>
            <Stack direction="column" height="100%" gap={2}>
              <Stack direction="row" justifyContent="space-evenly" gap={1}>
                <Stack direction="row" justifyContent="center" gap={1}>
                  <ComparePlayerCard
                    player={player1}
                    onDelete={() => setPlayer1(undefined)}
                    color="player1"
                    score={scoreP1}
                  />
                  {is2V2 && (
                    <ComparePlayerCard
                      player={player3}
                      onDelete={() => setPlayer3(undefined)}
                      color="player1"
                      score={scoreP3}
                    />
                  )}
                  <ComparePlayerCard
                    player={player2}
                    onDelete={() => setPlayer2(undefined)}
                    color="player2"
                    score={scoreP2}
                  />
                  {is2V2 && (
                    <ComparePlayerCard
                      player={player4}
                      onDelete={() => setPlayer4(undefined)}
                      color="player2"
                      score={scoreP4}
                    />
                  )}
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                >
                  {is2V2 && (
                    <Stack direction="row" gap={2}>
                      <Typography
                        sx={{
                          color: ({ palette }) => palette.common.player1.text,
                        }}
                      >
                        {scoreP1AndP3 ?? "..."}
                      </Typography>
                      <Typography
                        sx={{
                          color: ({ palette }) => palette.common.player2.text,
                        }}
                      >
                        {scoreP2AndP4 ?? "..."}
                      </Typography>
                    </Stack>
                  )}
                  <BanButton
                    badgeQuantity={draftParams?.jokerBans}
                    whoBan={
                      (draftParams?.jokerBans ?? 0) < 0 ? "player1" : "player2"
                    }
                    label={t("Bans joker")}
                  />
                  <BanButton
                    badgeQuantity={draftParams?.balancingBans}
                    whoBan={
                      (draftParams?.balancingBans ?? 0) < 0
                        ? "player1"
                        : "player2"
                    }
                    label={t("Bans d'équilibrage")}
                  />
                </Stack>
              </Stack>
              <Stack overflow="auto">
                {player1 && player2 && (is2V2 ? player3 && player4 : true) && (
                  <>
                    <Accordion
                      sx={{
                        bgcolor: ({ palette }) => palette.background.highlight,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Box
                          display="flex"
                          sx={{ svg: { width: "3.5rem", height: "3.5rem" } }}
                        >
                          <Icon5Star />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Divider orientation="horizontal" />
                          {charactersList
                            .filter((c) => c.rarity === 5)
                            .map((character, index) => (
                              <React.Fragment key={index}>
                                <RowCharacter
                                  character={character}
                                  is2V2={is2V2}
                                  constellationPlayer1={getConstellation(
                                    player1,
                                    character
                                  )}
                                  constellationPlayer2={getConstellation(
                                    player2,
                                    character
                                  )}
                                  constellationPlayer3={getConstellation(
                                    player3,
                                    character
                                  )}
                                  constellationPlayer4={getConstellation(
                                    player4,
                                    character
                                  )}
                                />
                                <Divider orientation="horizontal" />
                              </React.Fragment>
                            ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        bgcolor: ({ palette }) => palette.background.highlight,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Box
                          display="flex"
                          sx={{ svg: { width: "3.5rem", height: "3.5rem" } }}
                        >
                          <Icon4Star />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Divider orientation="horizontal" />
                          {charactersList
                            .filter((c) => c.rarity === 4)
                            .map((character, index) => (
                              <React.Fragment key={index}>
                                <RowCharacter
                                  character={character}
                                  is2V2={is2V2}
                                  constellationPlayer1={getConstellation(
                                    player1,
                                    character
                                  )}
                                  constellationPlayer2={getConstellation(
                                    player2,
                                    character
                                  )}
                                  constellationPlayer3={getConstellation(
                                    player3,
                                    character
                                  )}
                                  constellationPlayer4={getConstellation(
                                    player4,
                                    character
                                  )}
                                />
                                <Divider orientation="horizontal" />
                              </React.Fragment>
                            ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </WithGrow>
  );
};

export default PlayersBox;
