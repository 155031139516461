import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Palette,
  useTheme,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";
import Typography from "../Typography";

type GenshinButtonProps = MuiButtonProps & {
  loading?: boolean;
};

const GenshinButton = (props: PropsWithChildren<GenshinButtonProps>) => {
  const { disabled, loading = false, color } = props;
  const getColor = (palette: Palette) =>
    color === "error" ? palette.error.dark : palette.background.button;
  return (
    <MuiButton
      {...props}
      variant="contained"
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress color="inherit" size="1.5rem" />}
      sx={({ palette }) => ({
        borderRadius: "3rem",
        px: "2rem",
        border: disabled ? "1px solid gray" : `1px solid ${getColor(palette)}`,
        bgcolor: getColor(palette),
        ":hover": {
          bgcolor: getColor(palette),
        },
        ...props.sx,
      })}
    >
      <Typography
        size="sm"
        sx={{
          color: disabled ? "gray" : "black",
        }}
      >
        {props.children}
      </Typography>
    </MuiButton>
  );
};

export default GenshinButton;
