import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Character } from "types/Character/type";
import React from "react";
import { API_URL } from "service/root/rootApi";
import { Player } from "service/player/type";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import Typography from "common/components/Typography";

type RowCharacterProps = {
  character: Character;
  is2V2: boolean;
  constellationPlayer1?: number;
  constellationPlayer2?: number;
  constellationPlayer3?: number;
  constellationPlayer4?: number;
};

const RowCharacter = (props: RowCharacterProps) => {
  const {
    character,
    is2V2,
    constellationPlayer1,
    constellationPlayer2,
    constellationPlayer3,
    constellationPlayer4,
  } = props;

  const styleConstel: SxProps = is2V2
    ? { width: "5rem", paddingX: 7 }
    : { width: "2.75rem", padding: 0.4 };

  return (
    <Stack direction="row" alignItems="center" height="100%" padding={1}>
      <Stack flex={1} direction="row" alignItems="center" gap={1}>
        <CardMedia
          component="img"
          sx={{
            width: "4rem",
            borderRadius: "3rem",
            border: "1px solid gray",
          }}
          src={`${API_URL}assets/characters/${character.name}/thumb.png`}
          title={character.name}
        />
        <Typography size="sm">{character.name}</Typography>
      </Stack>
      <Stack
        flex={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="row"
          justifyContent="center"
          gap={2}
          visibility={
            constellationPlayer1 !== undefined ||
            (is2V2 && constellationPlayer3 !== undefined)
              ? "visible"
              : "hidden"
          }
          borderRadius="2rem"
          textAlign="center"
          fontSize="1.5rem"
          sx={{
            border: ({ palette }) =>
              `1px solid ${palette.common.player1.borderConstel}`,
            ...styleConstel,
          }}
        >
          <Stack
            visibility={
              constellationPlayer1 !== undefined ? "visible" : "hidden"
            }
          >
            {constellationPlayer1 ?? 0}
          </Stack>
          {is2V2 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Stack
                visibility={
                  constellationPlayer3 !== undefined ? "visible" : "hidden"
                }
              >
                {constellationPlayer3 ?? 0}
              </Stack>
            </>
          )}
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="row"
          justifyContent="center"
          gap={2}
          visibility={
            constellationPlayer2 !== undefined ||
            (is2V2 && constellationPlayer4 !== undefined)
              ? "visible"
              : "hidden"
          }
          borderRadius="2rem"
          textAlign="center"
          fontSize="1.5rem"
          sx={{
            border: ({ palette }) =>
              `1px solid ${palette.common.player2.borderConstel}`,
            ...styleConstel,
          }}
        >
          <Stack
            visibility={
              constellationPlayer2 !== undefined ? "visible" : "hidden"
            }
          >
            {constellationPlayer2 ?? 0}
          </Stack>
          {is2V2 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Stack
                visibility={
                  constellationPlayer4 !== undefined ? "visible" : "hidden"
                }
              >
                {constellationPlayer4 ?? 0}
              </Stack>
            </>
          )}
        </Stack>
        <Divider orientation="vertical" flexItem />
      </Stack>
    </Stack>
  );
};

export default RowCharacter;
