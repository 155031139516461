import { ContentCopy, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { RoomContext } from "App/context";
import Paper from "common/components/Paper";
import Typography from "common/components/Typography";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { useSocket } from "common/hooks/useSocket";
import {
  GameMode,
  GameModeDictionary,
  RoomParams,
  RoomPlayer,
} from "types/Room/type";
import { Player } from "types/Player/type";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorNotification } from "common/components/Notification";
import GenshinButton from "common/components/GenshinButton";
import { Sheet } from "service/sheet/type";
import { getSheets } from "service/sheet";

type CreateJoinRoomProps = {};

const CreateJoinRoom = (props: CreateJoinRoomProps) => {
  const { dispatch, localPlayer, availableSheets } = useContext(RoomContext);
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();
  const [roomName, setRoomName] = useState<string>("");
  const [selectedSheet, setSelectedSheet] = useState<number>();
  const [selectedGameMode, setSelectedGameMode] = useState<GameMode>(
    GameMode._1vs1
  );

  const subscribeEvents = () => {
    socket.on("join-room", (admin?: boolean) => {
      if (admin) dispatch({ admin: true });
      navigate("/waitingRoom");
    });
  };

  useEffect(() => {
    if (availableSheets.length > 0) setSelectedSheet(availableSheets[0].id);
  }, [availableSheets]);

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("join-room");
    };
  }, []);

  return (
    <Paper>
      <Stack gap={3} padding={1}>
        <Typography>{t("Créer ou rejoindre un salon")}</Typography>
        <Stack gap={1}>
          <FormControl size="small" required>
            <InputLabel>{t("Nom du salon")}</InputLabel>
            <OutlinedInput
              required
              value={roomName}
              onChange={(event) => setRoomName(event.target.value)}
              label={t("Nom du salon")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () =>
                      await navigator.clipboard.writeText(roomName)
                    }
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Accordion
            sx={{
              borderRadius: 1,
              bgcolor: ({ palette }) => palette.background.highlight,
              "::before": { display: "none" },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              {t("Paramètres")}
            </AccordionSummary>
            <AccordionDetails>
              <Stack gap={3}>
                <FormControl size="small" fullWidth>
                  <InputLabel>{t("Feuille d'équilibrage")}</InputLabel>
                  <Select
                    label={t("Feuille d'équilibrage")}
                    value={selectedSheet ?? ""}
                    onChange={(evt) =>
                      setSelectedSheet(evt.target.value as number)
                    }
                  >
                    {availableSheets.map((s) => (
                      <MenuItem value={s.id} key={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel>{t("Mode de jeu")}</InputLabel>
                  <Select
                    label={t("Mode de jeu")}
                    value={selectedGameMode}
                    onChange={(evt) =>
                      setSelectedGameMode(evt.target.value as GameMode)
                    }
                  >
                    {Object.values(GameMode).map((n, i) => (
                      <MenuItem value={n} key={i}>
                        {GameModeDictionary[n]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Tooltip
          arrow
          title={
            localPlayer?.pseudo === undefined ||
            localPlayer?.characters === undefined ||
            localPlayer?.characters.length === 0
              ? t("Ta box et ton pseudo doivent être configurés")
              : roomName === ""
              ? t("Le nom du salon ne peut pas être vide")
              : ""
          }
        >
          <span>
            <GenshinButton
              fullWidth
              disabled={
                roomName === "" ||
                selectedSheet === undefined ||
                localPlayer?.pseudo === undefined ||
                localPlayer?.characters === undefined ||
                localPlayer?.characters.length === 0
              }
              variant="contained"
              onClick={() => {
                const params: RoomParams = {
                  name: roomName,
                  sheet: {
                    id: selectedSheet ?? 1,
                    name:
                      availableSheets.find((s) => s.id === selectedSheet)
                        ?.name ?? "",
                  },
                  gameMode: selectedGameMode,
                };
                const player: RoomPlayer = {
                  id: localPlayer?.id,
                  roomId: socket.id,
                  pseudo: localPlayer?.pseudo,
                  box: localPlayer?.characters,
                  avatar: localPlayer?.avatar,
                  preferences: localPlayer?.preferences ?? [],
                };
                dispatch({ params });
                socket.emit("send-join-room", params, player);
              }}
            >
              {t("Rejoindre")}
            </GenshinButton>
          </span>
        </Tooltip>
      </Stack>
    </Paper>
  );
};

export default CreateJoinRoom;
